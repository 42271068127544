enum StorageType {
  LOCAL_STORAGE = 'localStorage',
  SESSION_STORAGE = 'sessionStorage',
}

function storageAvailable<T>(cb: (...args: unknown[]) => T) {
  try {
    return cb();
  } catch {
    return null;
  }
}

function getItem(key: string, storageType: StorageType) {
  return storageAvailable(() => window[storageType].getItem(key));
}

function setItem(key: string, value: string, storageType: StorageType) {
  return storageAvailable(() => window[storageType].setItem(key, value));
}

function removeItem(key: string, storageType: StorageType) {
  return storageAvailable(() => window[storageType].removeItem(key));
}

function getMultipleItems(keys: string[], storageType: StorageType) {
  return keys.map((key) => getItem(key, storageType));
}

function setMultipleItems(
  items: Array<{ key: string; value: string }>,
  storageType: StorageType
) {
  items.forEach(({ key, value }) => setItem(key, value, storageType));
}

function removeMultipleItems(keys: string[], storageType: StorageType) {
  return keys.map((key) => removeItem(key, storageType));
}

/*
  --------------------
  localStorage Helpers
  --------------------
*/

export function getLocalStorageItem(key: string) {
  return getItem(key, StorageType.LOCAL_STORAGE);
}

export function setLocalStorageItem(key: string, value: string) {
  return setItem(key, value, StorageType.LOCAL_STORAGE);
}

export function removeLocalStorageItem(key: string) {
  return removeItem(key, StorageType.LOCAL_STORAGE);
}

export function getMultipleLocalStorageItems(keys: string[]) {
  return getMultipleItems(keys, StorageType.LOCAL_STORAGE);
}

export function setMultipleLocalStorageItems(
  items: Array<{ key: string; value: string }>
) {
  return setMultipleItems(items, StorageType.LOCAL_STORAGE);
}

export function removeMultipleLocalStorageItems(keys: string[]) {
  return removeMultipleItems(keys, StorageType.LOCAL_STORAGE);
}

/*
  ----------------------
  sessionStorage Helpers
  ----------------------
*/

export function getSessionStorageItem(key: string) {
  return getItem(key, StorageType.SESSION_STORAGE);
}

export function setSessionStorageItem(key: string, value: string) {
  return setItem(key, value, StorageType.SESSION_STORAGE);
}

export function removeSessionStorageItem(key: string) {
  return removeItem(key, StorageType.SESSION_STORAGE);
}

export function getMultipleSessionStorageItems(keys: string[]) {
  return getMultipleItems(keys, StorageType.SESSION_STORAGE);
}

export function setMultipleSessionStorageItems(
  items: Array<{ key: string; value: string }>
) {
  return setMultipleItems(items, StorageType.SESSION_STORAGE);
}

export function removeMultipleSessionStorageItems(keys: string[]) {
  return removeMultipleItems(keys, StorageType.SESSION_STORAGE);
}
