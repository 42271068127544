const headers = import('next/headers');
import { getCookie } from 'cookies-next';

import { isServerCheck } from '@/modules/env/isServerCheck';
import { IN_APP_VIEW_KEY } from '@/constants/cookies';
import { X_NATIVE_APP } from '@/constants/headers';

export async function isInAppView() {
  if (!isServerCheck()) {
    /* Check cookie on client for page transitions */
    return getCookie(IN_APP_VIEW_KEY) === '1';
  }

  const headersFunction = await headers;

  return (
    Boolean((await headersFunction.headers()).has(X_NATIVE_APP)) ||
    (await headersFunction.cookies()).get(IN_APP_VIEW_KEY)?.value === '1'
  );
}
