const headers = import('next/headers');
import { getCookie } from 'cookies-next';

import {
  isJSONResponse,
  isOctetStreamResponse,
  isTextResponse,
  queryParamsFromObject,
} from './helpers';
import { AxiosCompatibleResponse, RequestConfig } from './types';

import {
  H_AUTHORIZATION,
  H_DEPOP_DEVICE_ID,
  X_COUNTRY_CODE,
  X_ENV_NAME,
} from '@/constants/headers';
import { ACCESS_TOKEN_KEY, PREVIEW_ENV_KEY } from '@/constants/cookies';
import { getPersistentId } from '@/modules/activityTracker/helpers';
import { isServerCheck } from '@/modules/env/isServerCheck';
import { Stdv1Stat } from '@/modules/observability/constants';

/**
 * Modify request headers to add optional auth and depop-specifics
 */
async function modifyRequestHeaders(config: RequestConfig) {
  const headersFunction = await headers;
  const h = new Headers(config.headers);

  if (!h.get('Content-Type')) {
    h.set('Content-Type', 'application/json');
  }

  if (isServerCheck()) {
    h.set('Accept-Encoding', 'gzip');

    // Forward the user's x-country-code to ensure that the x-country-code of the server is not applied during server side requests
    const countryCode = (await headersFunction.headers())
      .get(X_COUNTRY_CODE)
      ?.toUpperCase();

    if (countryCode) {
      h.set(X_COUNTRY_CODE, countryCode);
    }
  }

  if (config.withAuth) {
    let authCookie = getCookie(ACCESS_TOKEN_KEY);
    if (isServerCheck()) {
      authCookie = (await headersFunction.cookies()).get(ACCESS_TOKEN_KEY)
        ?.value;
    }
    if (authCookie) {
      h.set(H_AUTHORIZATION, `Bearer ${authCookie}`);
    }
  }

  if (config.withDeviceId) {
    const persistentIdCookie = await getPersistentId();
    if (persistentIdCookie) {
      h.set(H_DEPOP_DEVICE_ID, persistentIdCookie);
    }
  }

  // Send preview env header
  const previewEnv = getCookie(PREVIEW_ENV_KEY);
  if (previewEnv) {
    h.set(X_ENV_NAME, previewEnv);
  }

  return h;
}

/**
 * Only stringify data if it is a proper object, i.e.
 * not a file or FormData
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function processData(data: any) {
  if (data.constructor === Object || data.constructor === Array) {
    return JSON.stringify(data);
  }

  return data;
}

/**
 * Modified NextJS/Native Fetch to be compatible with Axios API
 */
async function patchedFetch<T = unknown>(
  config: RequestConfig
): Promise<AxiosCompatibleResponse<T>> {
  try {
    const headers = await modifyRequestHeaders(config);
    const queryParams = config.params
      ? queryParamsFromObject(config.params)
      : '';

    const builtConfig = {
      headers,
      method: config.method || 'GET',
      body: config.data ? processData(config.data) : undefined,
      cache: config.cache,
      next: {
        revalidate: config.revalidate,
      },
      keepalive: config.keepalive,
    };

    const response = await fetch(config.url + queryParams, builtConfig);

    let responseData;

    if (isJSONResponse(response)) {
      responseData = await response.json();
    } else if (isTextResponse(response)) {
      responseData = await response.text();
    } else if (isOctetStreamResponse(response)) {
      responseData = await response.arrayBuffer();
    } else {
      responseData = null;
    }

    if (!response?.ok) {
      throw {
        code: response?.status,
        config: builtConfig,
        response: {
          url: response?.url,
          data: responseData,
          statusText: response.statusText,
          status: response?.status,
        },
      };
    }

    return {
      data: responseData,
      status: response.status,
      headers: response.headers,
    };
  } catch (e) {
    /**
     * Use conditional dynamic import for the metrics module as this http
     * module is used in middleware, where the edge runtime does not support
     * the necessary native modules required for metrics.
     *
     * See:
     * - https://github.com/vercel/next.js/discussions/46722
     * - https://github.com/vercel/next.js/discussions/34179
     */
    if (process.env.NEXT_RUNTIME === 'nodejs') {
      const { recordMetric } = await import('@/modules/observability/metrics');

      recordMetric({
        type: 'increment',
        stat: Stdv1Stat['errors.http.networkError'],
      });
    }

    throw e;
  }
}

/**
 * Axios-like object for backwards compatibility with existing calls
 */
export const http = {
  get: function get<T>(url: string, config?: RequestConfig) {
    return patchedFetch<T>({ url, method: 'GET', ...config });
  },
  post: function post<T>(url: string, data?: unknown, config?: RequestConfig) {
    return patchedFetch<T>({ url, method: 'POST', data, ...config });
  },
  patch: function patch<T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ) {
    return patchedFetch<T>({ url, method: 'PATCH', data, ...config });
  },
  put: function put<T>(url: string, data?: unknown, config?: RequestConfig) {
    return patchedFetch<T>({ url, method: 'PUT', data, ...config });
  },
  delete: function del<T>(url: string, config?: RequestConfig) {
    return patchedFetch<T>({ url, method: 'DELETE', ...config });
  },
};

export default http;
