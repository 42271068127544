export const X_USER_DATA = 'X-User-Data';
export const X_PERSISTENT_ID = 'X-Persistent-Id';
export const H_AUTHORIZATION = 'Authorization';
export const H_DEPOP_DEVICE_ID = 'Depop-Device-Id';
export const X_NATIVE_APP = 'x-depop-native-app';
export const X_COUNTRY_CODE = 'x-country-code';
export const X_CITY_NAME = 'x-city-name';
export const H_BUCKET_TIME = 'Depop-Bucket-Time';
export const H_SESSION_ID = 'Depop-Session-Id';
export const X_DEPOP_PATH = 'X-Depop-Path';
export const X_NONCE = 'x-nonce';
export const H_CSP = 'Content-Security-Policy';
export const X_ENV_NAME = 'ot-baggage-x-env-name';
export const X_SEARCH_PARAMS = 'x-search-params';
export const X_SOURCE_PARTNER = 'x-source-partner';
