import { NextRequest, NextResponse } from 'next/server';
import { v4 as uuidv4 } from 'uuid';
import { addDays } from 'date-fns';
import { BroadcastChannel } from 'broadcast-channel';
import { getCookie } from 'cookies-next';
import { Params } from 'next/dist/server/request/params';

const headers = import('next/headers');

import {
  sessionStorageSessionIdKey,
  localStorageSessionIdKey,
  localStorageSessionTimestampKey,
  requestTypes,
  RequestType,
  sessionLandingPageKey,
  sessionProductViewsKey,
  newSessionBroadcastChannelName,
  newSessionBroadcastMessage,
  Platform,
} from './constants';
import { getRouteName } from './referers';

import {
  getSessionStorageItem,
  getMultipleLocalStorageItems,
  setMultipleSessionStorageItems,
  setMultipleLocalStorageItems,
  removeLocalStorageItem,
} from '@/modules/storage';
import { http } from '@/modules/http';
import {
  PERSISTENT_ID_KEY,
  PLATFORM_ID_KEY,
  RAKUTEN_COOKIE,
  RAKUTEN_LANDING_DATE_TIME,
  RAKUTEN_LANDING_ID,
  RAKUTEN_LANDING_UNIX_TIME,
  RAKUTEN_MERCHANT_ID,
} from '@/constants/cookies';
import { H_SESSION_ID, X_PERSISTENT_ID } from '@/constants/headers';
import {
  REFERRAL_EVENT_ENDPOINT,
  ACTIVITY_TRACKER_EVENT_ENDPOINT,
  ACTIVITY_TRACKER_SESSION_ENDPOINT,
} from '@/constants/endpoints';
import { isInAppView } from '@/utils/isInAppView';
import { isServerCheck } from '@/modules/env/isServerCheck';
import { constructDynamicRoute } from '@/modules/routing/constructDynamicRoute';
import { DeviceType } from '@/modules/device/types';

/**
 * We have to set the UUID as both cookie and header here, as there's currently
 * a bug in Next's cookie implementation where the set cookie value is not
 * immediately available during first render. Instead, we set the UUID as both
 * header and cookie, and our getPersistentId helper looks up the values in order.
 *
 * See https://github.com/vercel/next.js/issues/49442
 */
export function setPersistentIdMiddleware(req: NextRequest, res: NextResponse) {
  const existingPersistentId = req.cookies.get(PERSISTENT_ID_KEY)?.value;

  if (!existingPersistentId) {
    const uuid = uuidv4();
    res.headers.set(X_PERSISTENT_ID, uuid);
    res.cookies.set(PERSISTENT_ID_KEY, uuid, {
      expires: addDays(new Date(), 365),
    });
  }
}

export async function getPersistentId() {
  const headersFunction = await headers;
  if (isServerCheck()) {
    return (
      (await headersFunction.headers()).get(X_PERSISTENT_ID) ||
      (await headersFunction.cookies()).get(PERSISTENT_ID_KEY)?.value
    );
  }
  return getCookie(PERSISTENT_ID_KEY) as string;
}

export function getUrlParameters(url: string, urlParameter: string) {
  return new URL(url).searchParams.get(urlParameter);
}

export function getRakutenLandingTime(date: Date) {
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}${month}${day}_${hours}${minutes}`;
}

export function setRakutenCookieMiddleware(
  req: NextRequest,
  res: NextResponse
) {
  const ranMID = getUrlParameters(req.url, 'ranMID');
  const ranSiteID = getUrlParameters(req.url, 'ranSiteID');

  if (ranMID && ranSiteID) {
    const now = new Date();

    const utcLandingDate = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );

    const utcDate = new Date(utcLandingDate);

    const landingUnixTimestamp = Math.floor(utcDate.valueOf() / 1000);
    const landingDateTime = getRakutenLandingTime(utcDate);
    const expiryDate = utcDate.setUTCDate(utcDate.getUTCDate() + 30);

    const rakutenCookie = `${RAKUTEN_MERCHANT_ID}:${ranMID}|${RAKUTEN_LANDING_DATE_TIME}:${landingDateTime}|${RAKUTEN_LANDING_UNIX_TIME}:${landingUnixTimestamp}|${RAKUTEN_LANDING_ID}:${ranSiteID}`;

    const cookieConfig = {
      expires: expiryDate,
      domain: '.depop.com',
      path: '/',
      secure: true,
      SameSite: 'lax',
    };

    res.cookies.set(RAKUTEN_COOKIE, rakutenCookie, cookieConfig);
  }
}

export function getEndpoint(requestType: RequestType) {
  switch (requestType) {
    case requestTypes.event:
      return ACTIVITY_TRACKER_EVENT_ENDPOINT;
    case requestTypes.session:
      return ACTIVITY_TRACKER_SESSION_ENDPOINT;
    case requestTypes.referral:
      return REFERRAL_EVENT_ENDPOINT;
    default:
      return null;
  }
}

interface Options {
  [key: string]: unknown;

  includeAuth?: boolean;
}

export function sendData(
  requestType: RequestType,
  data: unknown,
  options?: Options
) {
  const headers: Record<string, string> = {};

  // send the AT session id if it's there
  if (requestType === requestTypes.session) {
    const requestData = Array.isArray(data) ? data[0] : data;
    if (requestData.id) {
      headers[H_SESSION_ID] = requestData.id;
    }
  }

  const url = getEndpoint(requestType);

  if (url) {
    return http.post(url, data, {
      headers,
      withAuth: options?.includeAuth,
      keepalive: true,
    });
  }
  // @todo: Proper error handling
  return Promise.reject('There was a problem');
}

export function getCurrentSession() {
  const [localStorageSessionId, localStorageSessionTimestamp] =
    getMultipleLocalStorageItems([
      localStorageSessionIdKey,
      localStorageSessionTimestampKey,
    ]);
  return {
    sessionStorageSessionId: getSessionStorageItem(sessionStorageSessionIdKey),
    localStorageSessionId,
    localStorageSessionTimestamp,
  };
}

export function startNewSession() {
  const sessionId = uuidv4();
  const timestamp = new Date(Date.now()).toISOString();
  const pageName = getRouteName(window.location.pathname);
  setMultipleSessionStorageItems([
    { key: sessionStorageSessionIdKey, value: sessionId },
  ]);
  setMultipleLocalStorageItems([
    {
      key: localStorageSessionIdKey,
      value: sessionId,
    },
    {
      key: localStorageSessionTimestampKey,
      value: timestamp,
    },
    { key: sessionLandingPageKey, value: pageName },
  ]);
  removeLocalStorageItem(sessionProductViewsKey);

  const channel = new BroadcastChannel(newSessionBroadcastChannelName);
  channel.postMessage(newSessionBroadcastMessage);
  channel.close();

  return {
    sessionId,
    timestamp,
  };
}

export function transformUserAgentToPlatformId(
  inAppView: boolean,
  userAgent?: string
): Platform {
  if (!inAppView || !userAgent) {
    return Platform.Web;
  }

  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    return Platform.iOS;
  } else if (userAgent.includes(DeviceType.ANDROID)) {
    return Platform.Android;
  }

  return Platform.Web;
}

export async function savePlatformToCookieMiddleware(res: NextResponse) {
  const headersFunction = await headers;

  const cookieConfig = {
    path: '/',
  };
  const inAppView = Boolean(await isInAppView());
  const userAgent =
    (await headersFunction.headers()).get('user-agent') || 'Unknown UA';

  const platformType = transformUserAgentToPlatformId(inAppView, userAgent);

  res.cookies.set(PLATFORM_ID_KEY, platformType, cookieConfig);
}

export async function getPlatform(): Promise<Platform> {
  const headersFunction = await headers;

  if (isServerCheck()) {
    return (await headersFunction.cookies()).get(PLATFORM_ID_KEY)
      ?.value as Platform;
  }

  return (getCookie(PLATFORM_ID_KEY) as Platform) || Platform.Web;
}

export function getProductSlug(params: Params, segments: string[]) {
  const dynamicRoute = constructDynamicRoute(params, segments);

  if (dynamicRoute.startsWith('/products/[slug]') && params.slug) {
    return Array.isArray(params.slug) ? params.slug[0] : params.slug;
  }
}
