export const RQ_USER_DATA_KEY = 'user_data';
export const RQ_PREVIEW_EXPS_CACHE = 'preview_experiments';
export const RQ_USER_COUNTERS_KEY = 'user_counters';
export const RQ_NAV_CONFIG = 'nav_config';
export const RQ_BAG_BASIC_KEY = 'bag_basic';
export const RQ_BAG_DETAILED_KEY = 'bag_detailed';
export const RQ_NUDGES_KEY = 'nudges';
export const RQ_SELLER_RECEIPTS_ONBOARDING_KEY = 'seller_receipts_onboarding';
export const RQ_SELLER_STATUS = 'seller_status';
export const RQ_REPOP_PRODUCTS = 'repop_products';
export const RQ_RECEIPTS = 'receipts';
export const RQ_RECEIPT = 'receipt';
export const RQ_SALES_REPORT = 'sales_report';
export const RQ_SELLER_HISTORICAL_DATA = 'seller_historical_data';
export const RQ_BULK_LISTING_ENABLED_KEY = 'bulk_listing_enabled';
export const RQ_PRODUCT_SEARCH_KEY = 'product_search';
export const RQ_SIZE_FILTER_TREE = 'size_filter_tree';
export const RQ_LEGACY_BRAND_CATEGORY_PAGE_METADATA_KEY =
  'legacy_brand_category_page_metadata';
export const RQ_LEGACY_CATEGORY_PAGE_METADATA_KEY =
  'legacy_category_page_metadata';
export const RQ_BRAND_CATEGORY_PAGE_METADATA_KEY =
  'brand_category_page_metadata';
export const RQ_CATEGORY_PAGE_METADATA_KEY = 'category_page_metadata';
export const RQ_LEGACY_SEARCH_CATEGORY_FILTERS = 'legacy_category_filters';
export const RQ_SEARCH_CATEGORY_FILTERS = 'category_filters';
export const RQ_CATEGORIES_BY_ID_KEY = 'categories_by_id';
export const RQ_CATEGORIES_BY_PATH_KEY = 'categories_by_path';
export const RQ_FILTER_AGGREGATES_KEY = 'filter_aggregates';
export const RQ_FILTER_AGGREGATES_SHOP_KEY = 'filter_aggregates_shop';
export const RQ_USER_EDUCATION_COUNTS_KEY = 'user_education_view_counts';
export const RQ_SHOP = 'shop';
export const RQ_SHOP_USERNAME_KEY = 'shop_username';
export const RQ_SHIPPING_PROVIDERS = 'shipping_providers';
export const RQ_MANUAL_SHIPPING_PROVIDERS = 'manual_shipping_providers';
export const RQ_SHIPPING_PROVIDERS_PARCELS = 'shipping_providers_parcels';
export const RQ_USER_SETTINGS = 'user_settings';
export const RQ_SEARCH_EXPLORE = 'explore';
export const RQ_SEARCH_EXPLORE_STYLE_EDIT = 'explore_style_edit';
export const RQ_USER_BALANCE = 'user_balance';
export const RQ_SAVED_PRODUCTS = 'saved_products';
export const RQ_LIKED_PRODUCTS = 'liked_products';
export const RQ_ACTIVE_SHOP_PRODUCTS = 'active_shop_products';
export const RQ_FILTERED_SHOP_PRODUCTS = 'filtered_shop_products';
export const RQ_SOLD_SHOP_PRODUCTS = 'sold_shop_products';
export const RQ_SHOP_PRODUCTS = 'shop_products';
export const RQ_FOLLOWING_RELATIONSHIP = 'following_relationship';
export const RQ_FOLLOWERS = 'followers';
export const RQ_FOLLOWING = 'following';
export const RQ_PRODUCT_ATTRIBUTES_KEY = 'product_attributes';
export const RQ_FILTER_PRODUCT_ATTRIBUTES_KEY = 'filter_product_attributes';
export const RQ_FEEDBACK_SELLER = 'feedback_seller';
export const RQ_FEEDBACK_BUYER = 'feedback_buyer';
export const RQ_FEEDBACK_CREATE = 'feedback_create';
export const RQ_DRAFT = 'draft';
export const RQ_READY_TO_POST_DRAFTS = 'readyToPostDrafts';
export const RQ_INCOMPLETE_DRAFTS = 'incompleteDrafts';
export const RQ_ALL_DRAFTS = 'allDrafts';
export const RQ_POST_DRAFT = 'postDraft';
export const RQ_BULK_POST_DRAFTS_ASYNC_JOB = 'bulkPostDraftsAsyncJob';
export const RQ_DELETE_DRAFT = 'deleteDraft';
export const RQ_BATCH_DELETE_DRAFTS = 'batchDeleteDrafts';
export const RQ_EDIT_DRAFTS = 'editDrafts';
export const RQ_EDIT_SINGLE_DRAFT = 'editSingleDraft';
export const RQ_CREATE_DRAFT = 'createDraft';
export const RQ_CREATE_DRAFTS = 'createDrafts';
export const RQ_CREATE_PRODUCT = 'createProduct';
export const RQ_UPDATE_PRODUCT = 'updateProduct';
export const RQ_CREATE_PRESIGNED_URL_V3 = 'createPresignedUrlV3';
export const RQ_ADD_IMAGE_TO_STORAGE = 'addImageToStorage';
export const RQ_SORTED_BRANDS_KEY = 'sortedBrands';
export const RQ_BRANDS_BY_ID_KEY = 'brandsById';
export const RQ_BRANDS_BATCH_BY_ID_KEY = 'brandsBatchById';
export const RQ_CREATE_STRIPE_USER = 'createStripeUser';
export const RQ_ADDRESS_DETAIL = 'addressSearchDetail';
export const RQ_REFINED_RESULTS = 'addressRefinedResults';
export const RQ_ADDRESS_SEARCH_TEXT = 'addressSearchText';
export const LEGACY_PRODUCT_BY_ID = 'legacy_product_by_id';
export const PRODUCT_BY_ID = 'product_by_id';
export const PRODUCT_BASIC_BY_SLUG = 'product_basic_by_slug';
export const PRODUCT_EXTENDED_BY_SLUG = 'product_extended_by_slug';
export const PRODUCT_LISTING_BY_SLUG = 'product_listing_by_slug';
export const RO_SHOP_PRODUCTS_SELLING_KEY = 'selling_products';
export const RQ_OUTFITS_KEY = 'paginated_outfits';
export const RQ_OUTFITS_CONFIG_KEY = 'outfits_config';
export const RQ_STORIES_CONFIG_KEY = 'stories_config';
export const RQ_STORIES_KEY = 'stories';
export const RQ_IMPORTED_OUTFITS_KEY = 'imported_outfits';
export const RQ_COLLAGE_RECOMMENDED_ITEMS = 'collage_recommended_items';
export const RQ_COLLAGE_PRODUCT_CATEGORIES = 'collage_product_categories';
export const RQ_OUTFIT_KEY = 'single_outfit';
export const RQ_STORIES_BY_USERNAME = 'stories_by_username';
export const RQ_SELLER_PRODUCTS_LISTED = 'seller_products_listed';
export const RQ_SELLER_PRODUCTS = 'seller_products';
export const RQ_BRAND_BY_SLUG_KEY = 'brand_by_slug';
export const RQ_BRANDS_BATCH_BY_SLUG_KEY = 'brands_batch_by_slug';
export const RQ_FULL_CATEGORIES_TREE_KEY = 'full_categories_tree';
export const RQ_CHECKOUT_SHIPPING_ADDRESSES = 'checkout_shipping_addresses';
export const RQ_CHECKOUT_SUMMARY = 'checkout_summary';
export const RQ_PURCHASE_COUNTS = 'purchase_counts';
export const RQ_PRODUCT_MORE_ITEMS = 'product_more_items';
export const RQ_SIMILAR_ITEMS = 'similar_items';
export const RQ_BULK_DELETE_PRODUCTS = 'bulk_delete_products';
export const RQ_GET_VACATION_PREFERENCES = 'getVacationPreferences';
export const RQ_UPDATE_VACATION_PREFERENCES = 'updateVacationPreferences';
export const RQ_SHOP_WIDE_JOB = 'shop_wide_job';
export const RQ_REMOVE_DISCOUNT = 'remove_discount';
export const RQ_UPDATE_DISCOUNT = 'update_discount';
export const RQ_REMOVE_SHOP_WIDE_DISCOUNT = 'remove_shop_wide_discount';
export const RQ_BULK_MARK_SOLD_PRODUCTS = 'bulk_mark_sold_products';
export const RQ_RECENTLY_VIEWED = 'recently_viewed';
export const RQ_BULK_UNBOOST_PRODUCTS = 'bulk_unboost_products';
export const RQ_BOOSTING_STATISTICS = 'boosting_statistics';
export const RQ_BOOSTING_ALL_LISTINGS = 'boosting_all_listings';
export const RQ_BOOSTING_BOOSTED_LISTINGS = 'boosting_boosted_listings';
export const RQ_BOOSTING_BOOSTED_SOLD_LISTINGS = 'boosting_sold_listings';
export const RQ_MFA_DEVICES_KEY = 'mfa_devices';
export const RQ_PRODUCT_PAYMENT_PROVIDERS = 'product_payment_providers';
export const RQ_CONVERSATIONS_LIST_KEY = 'conversations_list';
export const RQ_CONVERSATION_KEY = 'conversation';
export const RQ_CONVERSATION_BY_ID_KEY = 'conversation_by_id';
export const RQ_BUYER_ACTIVE_OFFERS = 'buyer_active_offers';
export const RQ_BUYER_INACTIVE_OFFERS = 'buyer_inactive_offers';
export const RQ_COUNTRIES = 'countries';
export const RQ_CMS_EXPLORE_PAGE = 'cms_explore_page';
export const RQ_BLOG_ARTICLES = 'blog_articles';
export const RQ_BLOG_ARTICLE = 'blog_article';
export const RQ_BATCH_SHOPS_BY_USERNAMES = 'batchShopsByUsernames';
export const RQ_MARKETING_PREFERENCES = 'marketing_preferences';
export const RQ_UPDATE_MARKETING_PREFERENCES = 'update_marketing_preferences';
export const RQ_CATEGORY_SIZE_MAP = 'category_size_map';
export const RQ_X_COUNTRY_CODE = 'x_country_code';
export const RQ_TEMPLATES = 'templates';
