export const ACCESS_TOKEN_KEY = 'access_token';
export const USER_ID_KEY = 'user_id';
export const HASHED_USER_EMAIL_KEY = 'hashed_user_email';
export const PERSISTENT_ID_KEY = 'ACTIVITY_TRACKER_PERSISTENT_SESSION_ID';
export const PLATFORM_ID_KEY = 'ATPlatformType';
export const FORCE_VARIANT_FLAG_NAME = 'DEPOP_FORCE_VARIANTS';
export const FORCE_EXPERIMENT_VARIANTS_NAME = 'DEPOP_EXP_OVERRIDES';
export const NEXT_LOCALE_KEY = 'NEXT_LOCALE';
export const LOCATION_KEY = 'location';
export const LANGUAGE_KEY = 'language';
export const FORCE_TRAFFIC_WEIGHT = 'NEW_ROUTING_BUCKETING_FORCED_WEIGHT';
export const PREVIEW_ENV_KEY = 'X-ENV-NAME';
export const IN_APP_VIEW_KEY = 'inAppView';
export const DEPOP_USERNAME = 'depop_username';
export const DEPOP_USERNAME_ONE_HOUR_EXPIRY = 3600;
export const MFA_TOKEN = 'pre_mfa_token';
export const MFA_TOKEN_OPTS = {
  path: '/',
  maxAge: 3600,
  secure: true,
};

export const RECENT_SEARCH_VAL = 'recent_search_value';

export const RAKUTEN_COOKIE = 'rmStoreGateway';
export const RAKUTEN_MERCHANT_ID = 'amid';
export const RAKUTEN_LANDING_ID = 'atrv';
export const RAKUTEN_LANDING_DATE_TIME = 'ald';
export const RAKUTEN_LANDING_UNIX_TIME = 'auld';
