import { Params } from 'next/dist/server/request/params';

export function constructDynamicRoute(params: Params, segments: string[] = []) {
  const modifiedSegments: string[] = [];

  segments.forEach((segment) => {
    const matchingParam = Object.entries(params).find(([_, paramValue]) => {
      if (Array.isArray(paramValue)) {
        return paramValue.join('/') === segment;
      }
      return paramValue === segment;
    });

    if (matchingParam?.length) {
      modifiedSegments.push(`[${matchingParam[0]}]`);
    } else {
      modifiedSegments.push(segment);
    }
  });

  return '/' + modifiedSegments.join('/');
}
