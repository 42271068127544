import { eventTypes as uiKitEventTypes } from '@depop/web-ui-kit/CookieBanner';
import { formatISO } from 'date-fns';
import { v4 } from 'uuid';
// TODO WEBPLAT-187 we will only have the cookie key when legacy experiments
// are gone
const persistentIdKey = 'ACTIVITY_TRACKER_PERSISTENT_SESSION_ID';
export const sessionStorageSessionIdKey = 'ACTIVITY_TRACKER_SESSION_ID';
export const sessionLandingPageKey = 'SESSION_LANDING_PAGE';
export const sessionProductViewsKey = 'SESSION_PRODUCT_VIEWS';
// TODO WEBPLAT-187 we will eventually only export the cookie one
// but I hate when we have a variable that has a name that makes no sense
// like localStoragePersistentIdKey for a cookie value. We're in transition here....
export const localStorageSessionIdKey = 'ACTIVITY_TRACKER_SESSION_ID';
export const localStorageSessionTimestampKey =
  'ACTIVITY_TRACKER_SESSION_TIMESTAMP';
export const cookiePersistentSessionIdKey = persistentIdKey;
export const sessionExternalProductIdKey = 'SESSION_EXTERNAL_PRODUCT_ID';
export const newSessionBroadcastChannelName = 'ATSessions';
export const newSessionBroadcastMessage = 'newSession';

export enum RequestType {
  session = 'SESSION',
  event = 'EVENT',
  referral = 'REFERRAL',
}

export const requestTypes = RequestType;

export enum ListingType {
  NEW = 'new',
  COPY = 'copy',
  EDIT = 'edit',
  DRAFT = 'draft',
  PREONBOARDING = 'preonboarding',
}

// for the page view event
export enum pageNames {
  SELL_ON_DEPOP = 'sell-on-depop',
  PARTNER_ADOBE = 'partners-adobe',
  YOUTUBE_LANDING = 'youtube',
  SELLINGHUB_DRAFTS_BULK_EDIT = 'sellinghub-drafts-bulk-edit',
  BLOG = 'blog',
  DEPOP_INSIDER = 'depop-insider',
}

export enum ActivityTrackerEventType {
  BROWSE_BY_ACTION = 'BrowseByAction',
  BROWSE_BY_RESULTS_VIEW = 'BrowseByResultsView',
  BROWSE_BY_VIEW = 'BrowseByView',
  CHAT_VIEW = 'ChatView', //used for conversation list
  CONVERSATION_VIEW = 'ConversationView', //view the messages of a conversation
  HOMEPAGE_VIEW = 'HomepageView',
  LISTING_DRAFTS_VIEW = 'ListingDraftsView',
  LISTING_VIEW = 'ListingView',
  LIVE_LISTINGS_VIEW = 'LiveListingsView',
  PRODUCT_VIEW = 'ProductView',
  RECEIPT_DETAILS_VIEW = 'ReceiptDetailsView',
  RECEIPT_LIST_VIEW = 'ReceiptListView',
  SEARCH_RESULTS_VIEW = 'SearchResultsView',
  EMPTY_SEARCH_RESULTS_VIEW = 'SearchNoResultsFoundView',
  SEARCH_VIEW = 'SearchView',
  SEARCH_BAR_VIEW = 'SearchBarView',
  SEARCH_BAR_SELECT_USER_ACTION = 'SearchBarSelectUserAction',
  TRACKED_URL_VIEW = 'TrackedUrlView',
  USER_PROFILE_VIEW = 'UserProfileView',
  USER_PROFILE_REVIEWS_VIEW = 'UserProfileReviewsView',
  WEB_CAMPAIGN_VIEW = 'WebCampaignView', //triggered if a utm_source parameter is present
  WELCOME_VIEW = 'WelcomeView', //the login page view
  SellerMoreItemsView = 'SellerMoreItemsView',
  NO_SIMILAR_ITEMS = 'NoSimilarItems',
  MODULE_CLICK_ACTION = 'ModuleClickAction',
  MODULE_INTERACTION_ACTION = 'ModuleInteractionAction',
  LANDING_PAGE_VIEW = 'LandingPageView',
  PRE_ONBOARDING_SKIP_LISTING_ACTION = 'PreOnboardingSkipListingAction',
  BRAND_SPAM_MODERATION_VIEW = 'BrandSpamModerationView',
  CONFIRM_LISTING_BRAND_SPAM_ACTION = 'ConfirmListingBrandSpamAction',
  BUYER_PROTECTION_LINK_ACTION = 'BuyerProtectionLinkAction',
  SHIPPING_LEARN_MORE_LINK_ACTION = 'SupportArticleView',

  LINK_RECEIVER_ACTION = 'LinkReceiverAction',

  // Generic Web Module Events
  MODULE_VIEW = 'GenericWebModuleView',
  MODULE_SCROLL_ACTION = 'GenericWebModuleScroll',
  MODULES_AVAILABLE = 'PageModulesAvailable',

  BUY_NOW_ACTION = 'BuyNowAction',
  CLEAR_FILTER_ACTION = 'ClearFilterAction',
  CLEAR_SEARCH_BAR_ACTION = 'ClearSearchBarAction',
  CONFIRM_DRAFT_ACTION = 'ConfirmDraftAction',
  CONFIRM_LISTING_ACTION = 'ConfirmListingAction',
  TIME_TO_LIST_ACTION = 'TimeToListAction',
  COPY_ADDRESS_ACTION = 'CopyAddressAction',
  COPY_LISTING_ACTION = 'CopyListingAction',
  CTA_ACTION = 'CtaAction',
  DELETE_DRAFT_ACTION = 'ListingDraftsDeletedAction',
  DELETE_PRODUCT_ACTION = 'DeleteProductAction',
  DOWNLOAD_SHIPPING_LABEL_ACTION = 'DownloadShippingLabelAction',
  EDIT_DRAFT_ACTION = 'EditDraftAction',
  EDIT_LISTING_ACTION = 'EditListingAction',
  FILTER_APPLIED_ACTION = 'FilterAppliedAction',
  FILTER_OPENED_ACTION = 'FilterOpenedAction',
  SORT_OPENED_ACTION = 'SortOpenedAction',
  SORT_APPLIED_ACTION = 'SortAppliedAction',
  FILTER_UNREAD_MESSAGE_ACTION = 'FilterUnreadMessageAction',
  GET_THE_APP_ACTION = 'GetTheAppAction',
  LOGIN_SUBMIT_ACTION = 'LoginSubmitAction',
  LOGIN_WELCOME_ACTION = 'WelcomeAction',
  LOGIN_SUCCESS_ACTION = 'LoginSuccessAction',
  LOGOUT_ACTION = 'LogOutAction',
  PRODUCT_INTERACTION_ACTION = 'ProductInteractionAction',
  PRODUCT_REVIEW_PROFILE_CLICK_ACTION = 'ProductReviewProfileClickAction',
  RECEIPT_MARKED_AS_SHIPPED_ACTION = 'ReceiptMarkedAsShippedAction',
  RESET_PASSWORD_ACTION = 'ResetPasswordAction',
  SALES_DOWNLOAD_ACTION = 'SalesDownloadAction',
  SEARCH_FILTER_ACTION = 'SearchFilterAction',
  SEARCH_RESULTS_QUERY_ACTION = 'SearchResultsQueryAction',
  SEARCH_RESULTS_PRODUCT_ACTION = 'SelectSearchResultsProductAction',
  SEARCH_RESULTS_SUGGESTED_ACTION = 'SearchResultsSuggestedQueryAction',
  SELECT_RELATED_SEARCH_ACTION = 'SelectRelatedSearchAction',
  SEE_MESSAGES_ACTION = 'SeeMessagesAction',
  SEND_MESSAGE_ACTION = 'SendMessageAction',
  SHARE_ACTION = 'ShareAction',
  START_LISTING_ACTION = 'StartListingAction',
  SUBMIT_FEEDBACK_ACTION = 'SubmitFeedbackAction',
  BOOST_IN_LISTING_LEARN_MORE_ACTION = 'BoostInListingLearnMoreAction',
  USER_INTERACTION_ACTION = 'UserInteractionAction',
  SELECT_WEB_PRODUCT_ACTION = 'SelectWebProductAction',
  ENABLE_ADD_ANOTHER_ACTION = 'EnableAddAnotherAction',

  LISTING_ATTRIBUTE_VIEW = 'ListingAttributeView',
  LISTING_ATTRIBUTE_SELECT_ACTION = 'ListingAttributeSelectAction',
  LISTING_ATTRIBUTE_UNSELECT_ACTION = 'ListingAttributeUnselectAction',
  LISTING_ATTRIBUTES_AUTOPOPULATE = 'VisualAiAttributesPopulated',
  LISTING_ATTRIBUTES_UNDO_VISUAL_AI_ACTION = 'VisualAiUndoAction',

  WEB_EXPERIMENT = 'WebExperimentInformation',

  MFA_OTP_VIEW = 'MfaLoginOtpInputView',
  MFA_OTP_BACK_ACTION = 'MfaLoginOtpInputBackAction',
  MFA_OTP_HELP_ACTION = 'MfaLoginOtpInputFallbackAction',
  MFA_LOGIN_ACTION = 'MfaLoginOtpInputContinueAction',
  MFA_HELP_VIEW = 'MfaLoginFallbackView',
  MFA_HELP_CONTINUE_ACTION = 'MfaLoginFallbackContinueAction',
  MFA_HELP_BACK_ACTION = 'MfaLoginFallbackBackAction',

  RECENTLY_VIEWED_ITEMS_INTERACTION_ACTION = 'RecentlyViewedItemsInteractionAction',

  SELLER_STATS_VIEW = 'SellerStatsView',
  SELLER_STATS_ACTION = 'SellerStatsAction',
  SELLER_STATS_EDUCATION_ACTION = 'SellerStatsEducationAction',
  SELLER_SHIPPING_VIEW = 'ShippingPreferencesView',

  SETUP_SHOP_INTRO_V2_VIEW = 'SetupShopIntroV2View',
  SETUP_SHOP_BUSINESS_ONBOARDING_V2_VIEW = 'SetupShopBusinessOnboardingV2View',
  SETUP_SHOP_BUSINESS_ONBOARDING_V2_ACTION = 'SetupShopBusinessOnboardingV2Action',
  SETUP_SHOP_CONNECT_PAYPAL_V2_VIEW = 'SetupShopConnectPaypalV2View',
  SETUP_SHOP_CONNECT_PAYPAL_V2_ACTION = 'SetupShopConnectPaypalV2Action',
  SETUP_SHOP_DATE_OF_BIRTH_V2_VIEW = 'SetupShopDateofbirthV2View',
  SETUP_SHOP_DEPOP_PAYMENTS_V2_VIEW = 'SetupShopDepopPaymentsV2View',
  SETUP_SHOP_BILLING_ADDRESS_V2_VIEW = 'SetupShopBillingAddressV2View',
  SETUP_SHOP_WEB_BILLING_ADDRESS_SUCCESS_ACTION = 'SetupShopWebBillingAddressSuccessAction',
  SETUP_SHOP_TOAST_V2_VIEW = 'SetupShopToastV2View',

  SELLER_HUB_VIEW = 'SellerHubView',
  START_SELLER_HUB_ACTION = 'StartSellerHubAction',

  START_BULK_LISTING_ACTION = 'StartBulkListingAction',
  BULK_LISTING_VIEW = 'BulkListingView',
  BULK_LISTING_VIEW_TEMPLATE_ACTION = 'BulkListingViewTemplateAction',
  BULK_LISTING_UPLOAD_ACTION = 'BulkListingUploadAction',
  BULK_LISTING_EDUCATION_VIEW = 'BulkListingEducationView',
  BULK_LISTING_CONFIRM_LISTING_ACTION = 'ConfirmBulkListingAction',
  BULK_LISTING_CONFIRM_DRAFTS_BULK_EDIT = 'ConfirmDraftsBulkEditAction',
  BULK_LISTING_ALL_DRAFTS_LOADED = 'BulkListingAllDraftsLoaded',
  BULK_LISTING_LOAD_MORE_DRAFTS_ACTION = 'BulkListingLoadMoreDraftsAction',
  BULK_LISTING_SELECT_ALL_CLICK_ACTION = 'BulkListingSelectAllClickAction',

  SET_DISCOUNT_ACTION = 'SetDiscountAction',
  SAVE_DISCOUNT_ACTION = 'SaveDiscountAction',
  END_DISCOUNT_ACTION = 'EndDiscountAction',

  WEB_PAYMENTS_SIGNPOSTING_VIEW = 'WebPaymentsSignpostingView',
  WEB_PAYMENTS_SIGNPOSTING_DOWNLOAD_ACTION = 'WebPaymentsSignpostingDownloadAction',

  CHECKOUT_SUMMARY_VIEW = 'CheckoutSummaryView',
  START_PAYMENT_ACTION = 'StartPaymentAction',
  PURCHASE_CONFIRMATION_VIEW = 'PurchaseConfirmationView',
  DISCOVER_MORE_ITEMS_ACTION = 'DiscoverMoreItemsAction',
  NAVBAR_LIKES_ACTION = 'NavbarLikesAction',
  ADDRESS_CONFIRMATION_ACTION = 'AddressConfirmationAction',

  DRC_DASHBOARD_VIEW = 'DrcDashboardView',
  DRC_DASHBOARD_ACTION = 'DrcDashboardAction',
  DRC_ALL_ITEMS_VIEW = 'DrcAllItemsView',
  DRC_ALL_ITEMS_ACTION = 'DrcAllItemsAction',
  DRC_GET_HELP_WITH_ORDER_VIEW = 'DrcGetHelpWithOrderView',
  DRC_GET_HELP_WITH_ORDER_ACTION = 'DrcGetHelpWithOrderAction',
  DRC_ERROR_VIEW = 'DrcErrorView',
  DRC_MY_ITEM_HAS_NOT_ARRIVED_VIEW = 'DrcMyItemHasNotArrivedView',
  DRC_MY_ITEM_HAS_NOT_ARRIVED_ACTION = 'DrcMyItemHasNotArrivedAction',
  DRC_MY_ITEM_HAS_NOT_ARRIVED_SECONDARY_REASON_VIEW = 'DrcMyItemHasNotArrivedSecondaryReasonView',
  DRC_MY_ITEM_HAS_NOT_ARRIVED_SECONDARY_REASON_ACTION = 'DrcMyItemHasNotArrivedSecondaryReasonAction',
  DRC_CHECK_DETAILS_VIEW = 'DrcCheckDetailsView',
  DRC_CHECK_DETAILS_ACTION = 'DrcCheckDetailsAction',
  DRC_CONFIRMATION_VIEW = 'DrcConfirmationView',
  DRC_SELLER_REVIEW_DISPUTE_VIEW = 'DrcSellerReviewDisputeView',
  DRC_SELLER_REVIEW_DISPUTE_ACTION = 'DrcSellerReviewDisputeAction',
  DRC_SELLER_SELECT_RESPONSE_VIEW = 'DrcSellerSelectResponseView',
  DRC_SELLER_SELECT_RESPONSE_ACTION = 'DrcSellerSelectResponseAction',
  DRC_DISPUTE_DETAILS_PAGE_VIEW = 'DrcDisputeDetailsPageView',
  DRC_DISPUTE_DETAILS_PAGE_ACTION = 'DrcDisputeDetailsPageAction',
  DRC_DISPUTE_DETAILS_PAGE_FOLLOW_UP_REASON_ACTION = 'DrcDisputeDetailsPageFollowUpReasonAction',

  SIGNUP_ACTION = 'SignupAction',
  SIGNUP_SUBMIT_ACTION = 'SignupSubmitAction',
  SIGNUP_MODAL_VIEW = 'SignupModalView',
  SIGNUP_MODAL_ACTION = 'SignupModalAction',
  SIGNUP_VIEW = 'SignupView',
  SIGNUP_SUCCESS = 'UserSignupSuccessAction',
  LINK_ACCOUNT_SUCCESS = 'UserLinkAccountSuccessAction',

  BOOST_LISTINGS_ALL_TAB_VIEW = 'BoostListingsTabView',
  BOOST_LISTINGS_BOOSTED_TAB_VIEW = 'BoostBoostedTabView',
  BOOST_LISTINGS_BOOSTED_TAB_SOLD_VIEW = 'BoostSoldView',
  BOOST_HELP_ACTION = 'BoostHelpAction',
  BOOST_SUMMARY_VIEW = 'BoostSummaryView',
  BOOST_CONFIRM_ACTION = 'BoostConfirmAction',
  BOOST_BULK_REMOVE_ACTION = 'BoostBulkRemoveAction',
  BOOST_TERMS_AND_CONDITIONS_ACTION = 'BoostTermsConditionsAction',

  PRODUCT_IMPRESSIONS = 'ProductImpressions',

  BAG_VIEW = 'BagView',
  ADD_ITEM_TO_BAG_ACTION = 'AddItemToBagAction',
  WEB_BAG_QUICK_ACCESS_VIEW = 'WebBagQuickAccessView',
  WEB_BAG_VIEW_ACTION = 'WebBagViewAction',
  WEB_BAG_CHECKOUT_ACTION = 'WebBagCheckoutAction',

  VACATION_MODE_VIEW = 'VacationModeView',
  SELLER_HUB_VACATION_MODE_ACTION = 'SellerHubVacationModeAction',
  BAG_VACATION_MODE_NUDGE_VIEW = 'BagVacationModeNudgeView',

  ENGAGED_SESSION_ACTION = 'EngagedSessionAction',
  WEB_TEST_NEW_SESSION_VIEW = 'WebTestNewSessionView',
  WEB_LOAD_MORE_ACTION = 'WebLoadMoreAction',
  WEB_MODULE_ATTRIBUTION_SEARCH_RESULTS_VIEW = 'WebModuleAttributionSearchResultsView',
  WEB_MODULE_ATTRIBUTION_PRODUCT_VIEW = 'WebModuleAttributionProductView',

  EXPLORE_RESULTS_VIEW = 'ExploreResultsView',

  SHOP_FILTER_ACTION = 'ShopFilterAction',
  SHOP_REARRANGE_ACTION = 'ShopRearrangeAction',
  SHOP_PREFERENCE_CHANGE_ACTION = 'ShopPreferenceChangedAction',
  SHOP_MANAGE_LISTINGS_VIEW = 'ManageListingsView',

  MAKE_OFFER_VIEW = 'MakeOfferView',
  OFFER_SENT_VIEW = 'OfferSentView',
  SEND_OFFER_ACTION = 'SendOfferAction',
  MAKE_OFFER_ACTION = 'MakeOfferAction',
  MAKE_OFFER_CLICK_ACTION = 'MakeOfferClickAction',
  OFFER_CHAT_VIEW = 'OfferChatView',
  OFFER_BUY_NOW = 'OfferBuyNowAction',
  SELLER_HUB_OFFERS_ACTION = 'SellerHubOffersAction',
  SELLER_HUB_OFFERS_LIST_VIEW = 'SellerHubOffersListView',
  SELLER_HUB_OFFER_DETAILS_ACTION = 'SellerHubOfferDetailsAction',
  SELLER_HUB_OFFER_DETAILS_VIEW = 'SellerHubOfferDetailsView',
  SELLER_HUB_OFFER_ACTION = 'SellerHubOfferAction',
  SUGGESTED_OFFERS_ACTION = 'SuggestedOffersAction',

  NAVBAR_CLICK_ACTION = 'NavBarClickAction',

  LANGUAGE_SELECT_ACTION = 'LanguageSelectAction',
  LOCATION_SELECT_ACTION = 'LocationSelectAction',

  CHANGE_PASSWORD_VIEW = 'ChangePasswordView',
  RESET_PASSWORD_VIEW = 'ResetPasswordView',
  // generic web page view event
  WEB_PAGE_VIEW = 'WebPageView',

  OUTFITS_FEED_VIEW = 'OutfitFeedView',
  OUTFITS_DETAIL_VIEW = 'OutfitDetailView',
  OUTFITS_CREATE_VIEW = 'OutfitCreateView',
  OUTFITS_MODERATE_ACTION = 'OutfitModerateAction',
  OUTFITS_UPLOAD_ACTION = 'OutfitUploadAction',
  OUTFIT_SEARCH_ITEM_ACTION = 'OutfitSearchItemAction',
  OUTFIT_REACTION_ACTION = 'OutfitReactionAction',
  OUTFIT_REACTION_REMOVE_ACTION = 'OutfitReactionRemoveAction',

  LOGIN_MAGIC_LINK_SENT_VIEW = 'LoginMagicLinkSentView',
  LOGIN_MAGIC_LINK_NO_LINK_VIEW = 'LoginMagicLinkNoLinkView',
  LOGIN_MAGIC_LINK_SENT_NO_LINK_ACTION = 'LoginMagicLinkSentNoLinkAction',
  LOGIN_MAGIC_LINK_ERROR_RESEND_ACTION = 'LoginMagicLinkErrorResendAction',
  LOGIN_MAGIC_LINK_ERROR_USE_PASSWORD_ACTION = 'LoginMagicLinkErrorUsePasswordAction',
  LOGIN_MAGIC_LINK_EDUCATION_VIEW = 'LoginMagicLinkEducationView',
  LOGIN_MAGIC_LINK_EDUCATION_SEND_LINK_ACTION = 'LoginMagicLinkEducationSendLinkAction',
  LOGIN_MAGIC_LINK_EDUCATION_USE_PASSWORD_ACTION = 'LoginMagicLinkEducationUsePasswordAction',
  LOGIN_MAGIC_LINK_EMAIL_ONLY_ACTION = 'LoginScreenEmailOnlyContinueAction',
  LOGIN_MAGIC_LINK_EMAIL_ONLY_VIEW = 'LoginScreenEmailOnlyView',
  DRAFTS_INLINE_EDIT_ACTION = 'DraftsInlineEditAction',
  DRAFTS_IMAGE_UPLOAD_ACTION = 'DraftsImageUploadAction',

  BLOG_ARTICLE_VIEW = 'BlogArticleView',
  BLOG_INTERACTION_ACTION = 'BlogInteractionAction',

  LOGIN_MAGIC_LINK_WEB_MOBILE_LANDING_VIEW = 'LoginMagicLinkMobileToWebLandingView',
  LOGIN_MAGIC_LINK_CONTINUE_IN_APP_ACTION = 'LoginMagicLinkContinueInAppAction',
  LOGIN_MAGIC_LINK_CONTINUE_IN_WEB_ACTION = 'LoginMagicLinkContinueInWebAction',

  SIGNUP_LOGIN_MAGIC_LINK_LANDING_VIEW = 'SignupMagicLinkMobileToWebLandingView',
  SIGNUP_LOGIN_MAGIC_LINK_CONTINUE_IN_APP_ACTION = 'SignupMagicLinkContinueInAppAction',
  SIGNUP_LOGIN_MAGIC_LINK_CONTINUE_IN_WEB_ACTION = 'SignupMagicLinkContinueInWebAction',
  SIGNUP_LOGIN_MAGIC_LINK_ERROR_VIEW = 'SignupLoginMagicLinkErrorView',
  SIGNUP_LOGIN_MAGIC_LINK_GET_HELP_ACTION = 'SignupLoginMagicLinkGetHelpAction',
  SIGNUP_LOGIN_MAGIC_LINK_EXPIRED_LINK_RESEND_ACTION = 'SignupLoginMagicLinkExpiredLinkResendLinkAction',
  SIGNUP_LOGIN_MAGIC_LINK_HELP_VIEW = 'SignupLoginMagicLinkHelpView',
  SIGNUP_LOGIN_MAGIC_LINK_HELP_CONTACT_US_ACTION = 'SignupLoginMagicLinkHelpContactUsAction',
  SIGNUP_LOGIN_MAGIC_LINK_SENT_VIEW = 'SignupLoginMagicLinkSentView',
  SIGNUP_LOGIN_MAGIC_LINK_RESEND_LINK_ACTION = 'SignupLoginMagicLinkResendLinkAction',
  SIGNUP_LOGIN_VIEW = 'SignupLoginView',
  SIGNUP_LOGIN_CONTINUE_ACTION = 'SignupLoginScreenContinueAction',
  SIGNUP_LOGIN_MAGIC_LINK_RATE_LIMIT_ACTION = 'SignupLoginMagicLinkRateLimitAction',
  SIGNUP_LOGIN_MAGIC_LINK_BANNED_USER_CONTACT_US_ACTION = 'SignupLoginMagicLinkBannedUserContactUsAction',
  SIGNUP_LOGIN_MAGIC_LINK_BANNED_USER_OK_CLICK_ACTION = 'SignupLoginMagicLinkBannedUserOkClickAction',
}

export type EventTypes = ActivityTrackerEventType | uiKitEventTypes;

export const moduleOriginParamName = 'moduleOrigin';

export enum ReferralEventType {
  PAGEVIEW = 'PAGEVIEW',
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
}

export const referralEventTypes = ReferralEventType;

export const interactionTypes = {
  PRODUCT_SAVE: 'ProductSave',
  PRODUCT_UNSAVE: 'ProductUnsave',
  PRODUCT_SIMILAR_ITEMS: 'ProductSimilarItems',
  MODULE_SEE_MORE_CLICKED: 'ModuleSeeMoreClicked',
};

export function getDefaultSessionData() {
  return {
    schemaVersion: '1.0',
    appVersion: `2.0-${process.env.NEXT_PUBLIC_APP_VERSION || 'unknown'}`,
    platformModifier: window.navigator.userAgent,
    events: [],
    experiments: [],
    referrals: [],
  };
}

export function session(
  sessionId: string,
  persistentIdentifier: string,
  experiments: string[],
  currentUserId: number | undefined,
  platform: Platform
) {
  const data = {
    ...getDefaultSessionData(),
    id: sessionId,
    userIds: currentUserId ? [currentUserId] : [],
    persistentIdentifier,
    platform,
    experiments,
  };
  return data;
}

export function getDefaultEventData() {
  return {
    schemaVersion: '2.0',
    loadedComponents: [],
    eventJourney: [],
    appVersion: `2.0-${process.env.NEXT_PUBLIC_APP_VERSION || 'unknown'}`,
    eventTime: formatISO(new Date(Date.now())),
    id: v4(),
  };
}

export function event(
  eventType: string,
  transitionFrom: string | undefined,
  sessionId: string,
  platform: Platform,
  persistentIdentifier: string,
  userId: number | undefined | null,
  params: { [key: string]: unknown },
  baseEventOverrides: { [key: string]: unknown }
) {
  return {
    schemaVersion: '2.0',
    baseEvent: {
      ...getDefaultEventData(),
      eventType,
      transitionFrom,
      sessionId,
      platform,
      persistentIdentifier,
      userId,
      ...baseEventOverrides,
    },
    ...params,
  };
}

export interface UtmTags {
  source?: string;
  campaign?: string;
  medium?: string;
  content?: string;
  term?: string;
}

interface ReferralEvent {
  id: string;
  event_type: ReferralEventType;
  event_time: number;
  persistent_id: string;
  session_id?: string;
  user_id?: string;
  platform: string;
  url?: string;
  url_parameters?: Record<string, string>;
  referer?: string;
  product_slug?: string;
  campaign_utm_tags?: UtmTags;
}

export function referralEvent(
  id: string,
  eventType: ReferralEventType,
  eventTime: number,
  sessionId: string,
  persistentId: string,
  userId: string | undefined,
  platform: string,
  url?: string,
  urlParameters?: Record<string, string>,
  referer?: string,
  productSlug?: string,
  campaignUtmTags?: UtmTags
): ReferralEvent {
  return {
    id,
    event_type: eventType,
    event_time: eventTime,
    session_id: sessionId,
    persistent_id: persistentId,
    user_id: userId,
    platform,
    url,
    url_parameters: urlParameters,
    referer,
    product_slug: productSlug,
    campaign_utm_tags: campaignUtmTags,
  };
}

export enum BrowseByTypes {
  CATEGORY = 'category',
  BRAND = 'brand',
  BRAND_CATEGORY = 'brand-category',
  THEME = 'theme',
  SITEMAP = 'sitemap',
  BREADCRUMB = 'breadcrumb',
  EXPLORE = 'explore',
  EXPLORE_RESULTS = 'explore-results',
  SALE = 'sale',
  SELL_ONLINE = 'sell-online',
}

export type ProductCarouselInteraction =
  | 'ProductCarouselImageThumbnailClick'
  | 'ProductCarouselDotClick'
  | 'ProductImagePreviewArrowRight'
  | 'ProductImagePreviewArrowLeft'
  | 'ProductImageSwipeRight'
  | 'ProductImageSwipeLeft';

export enum ModuleInteractionType {
  ProductClicked = 'ModuleProductClicked',
  ProductClickedInModal = 'ModuleProductClickedInModal',
  SeeMoreClicked = 'ModuleSeeMoreClicked',
  ModalClosed = 'ModuleModalClosed',
  MakeOfferHomePageSeeMore = 'MakeOfferHomePageSeeMore',
}

export enum HomePageModuleNames {
  PopularCategories = 'popular_categories',
  AlternateCategories = 'alternate_categories',
  TrendingProducts = 'trending_products',
  LikesAndSaves = 'likes_and_saves',
  Likes = 'likes',
  Saves = 'saves',
  DynamicTopSearches = 'dynamic_top_searches',
  RecentFromYourSearch = 'recent_from_your_search',
  SimilarToRecentViews = 'similar_to_recent_views',
  RecentlyViewed = 'recently_viewed',
  DepopEdit = 'depop_edit',
  MeetSellers = 'meet_sellers',
  BrandedCategories = 'branded_categories',
  BrandedCategoryPills = 'branded_category_pills',
  FreeFeesBanner = 'free_fees_banner',
  PricePoint = 'price_point',
  RepopBanner = 'repop_banner',
  PopularThisWeek = 'selling_trends',
  ShopByStyle = 'shop_by_style',
  ShopByPrice = 'shop_by_price',
  SuggestedForYou = 'style_edit',
  ActiveOffers = 'your_active_offers',
}

export enum ModuleNames {
  BrandSuggestions = 'brand_suggestion',
  SubcategorySuggestions = 'subcategory_suggestion',
  HomepageFeaturedCategories = 'homepage_featured_categories',
}

export enum SignupPageNames {
  Main = 'Intro',
  UserDetails = 'YourDetails',
  ThirdParty = 'ThirdPartyDetails',
  PhoneEntry = 'PhoneNumber',
  PhoneConfirm = 'ValidateNumber',
}

export enum ShopRearrangeMethods {
  Rearrange = 'rearrange',
  MoveSold = 'move_sold_down',
}

export enum ShopProfileSection {
  Shop = 'shop',
  Likes = 'likes',
  Saved = 'saved',
  Selling = 'selling',
  Sold = 'sold',
}

export enum NavigationComponentTypes {
  TextLink = 'text_link',
  TextLinkFooter = 'text_link_footer',
  ImageLink = 'image_link',
}

export enum TrackingReferers {
  AOL = 'external.aol.view',
  ASK = 'external.ask.view',
  BAIDU = 'external.baidu.view',
  BIGLOBE = 'external.biglobe.view',
  BING = 'external.bing.view',
  CENTRUM = 'external.centrum.view',
  DAUM = 'external.daum.view',
  DEEPLINK = 'external.deeplink.view',
  DOCOMO = 'external.docomo.view',
  DUCKDUCKGO = 'external.duckduckgo.view',
  ECOSIA = 'external.ecosia.view',
  FACEBOOK = 'external.facebook.view',
  GOMAIL = 'external.gomail.view',
  GOOGLE = 'external.google.view',
  GOONE = 'external.goone.view',
  INSTAGRAM = 'external.instagram.view',
  INSTAGRAM_STORIES = 'external.instagramstories.view',
  KVASIR = 'external.kvasir.view',
  NAVER = 'external.naver.view',
  OTHER = 'external.other.view',
  PINTEREST = 'external.pinterest.view',
  ONET = 'external.onet.view',
  QWANT = 'external.qwant.view',
  RAMBLER = 'external.rambler.view',
  SEZNAM = 'external.seznam.view',
  SOGOU = 'external.sogou.view',
  STARTSIDEN = 'external.startsiden.view',
  TWITTER = 'external.twitter.view',
  VIRGILIO = 'external.virgilio.view',
  YAHOO = 'external.yahoo.view',
  YANDEX = 'external.yandex.view',
  YOUTUBE = 'external.youtube.view',
}

export const INTERNAL_REFERER = 'direct';

export const refererMatches = {
  aol: { hostMatch: 'aol.com', referer: TrackingReferers.AOL },
  ask: { hostMatch: 'ask.com', referer: TrackingReferers.ASK },
  baidu: { hostMatch: 'baidu.com', referer: TrackingReferers.BAIDU },
  biglobe: { hostMatch: 'biglobe.ne.jp', referer: TrackingReferers.BIGLOBE },
  bing: { hostMatch: 'bing.com', referer: TrackingReferers.BING },
  centrum: { hostMatch: 'centrum.cz', referer: TrackingReferers.CENTRUM },
  daum: { hostMatch: 'daum.net', referer: TrackingReferers.DAUM },
  deeplink: { hostMatch: 'depop.app.link', referer: TrackingReferers.DEEPLINK },
  docomo: { hostMatch: 'search.smt.docomo', referer: TrackingReferers.DOCOMO },
  duckduckgo: {
    hostMatch: 'duckduckgo.com',
    referer: TrackingReferers.DUCKDUCKGO,
  },
  ecosia: { hostMatch: 'ecosia.org', referer: TrackingReferers.ECOSIA },
  facebook: { hostMatch: 'facebook.com', referer: TrackingReferers.FACEBOOK },
  gomail: { hostMatch: 'go.mail.ru', referer: TrackingReferers.GOMAIL },
  google: { hostMatch: 'google', referer: TrackingReferers.GOOGLE },
  goone: { hostMatch: 'goo.ne', referer: TrackingReferers.GOONE },
  instagram: {
    hostMatch: 'instagram.com',
    referer: TrackingReferers.INSTAGRAM,
  },
  kvasir: { hostMatch: 'kvasir.no', referer: TrackingReferers.KVASIR },
  naver: { hostMatch: 'naver.com', referer: TrackingReferers.NAVER },
  onet: { hostMatch: 'onet.pl', referer: TrackingReferers.ONET },
  pinterest: {
    hostMatch: 'pinterest.com',
    referer: TrackingReferers.PINTEREST,
  },
  qwant: { hostMatch: 'qwant.com', referer: TrackingReferers.QWANT },
  rambler: { hostMatch: 'rambler.ru', referer: TrackingReferers.RAMBLER },
  seznam: { hostMatch: 'seznam.cz', referer: TrackingReferers.SEZNAM },
  sogou: { hostMatch: 'sogou.com', referer: TrackingReferers.SOGOU },
  startsiden: {
    hostMatch: 'startsiden.no',
    referer: TrackingReferers.STARTSIDEN,
  },
  twitter: { hostMatch: 'twitter.com', referer: TrackingReferers.TWITTER },
  virgilio: { hostMatch: 'virgilio.it', referer: TrackingReferers.VIRGILIO },
  yahoo: { hostMatch: 'yahoo.com', referer: TrackingReferers.YAHOO },
  yandex: { hostMatch: 'yandex.com', referer: TrackingReferers.YANDEX },
  youtube: { hostMatch: 'youtube.com', referer: TrackingReferers.YOUTUBE },
};

export type refererKeys = keyof typeof refererMatches;

enum GroupNames {
  About = 'About',
  Bag = 'Bag',
  Blog = 'Blog',
  BrandCategoryResults = 'Brand Category Results',
  BrandResults = 'Brand Results',
  BulkListing = 'Bulk listing',
  CategoryResults = 'Category Results',
  CategorySell = 'Category Sell on Depop',
  Chat = 'Chat',
  Checkout = 'Checkout',
  Disputes = 'Disputes',
  Drafts = 'Drafts',
  Explore = 'Explore',
  ExploreResults = 'Explore Results',
  Homepage = 'Homepage',
  Likes = 'Likes',
  Listing = 'List Item',
  Login = 'login',
  MagicLink = 'Magic Link',
  Marketing = 'Marketing',
  NoMatch = '',
  Offers = 'Offers',
  PasswordReset = 'PasswordReset',
  Product = 'Product',
  Purchases = 'Purchases',
  Receipts = 'Receipts',
  Saves = 'Saves',
  Search = 'Search',
  SearchResults = 'Search Results',
  Sell = 'Sell on Depop',
  SellCategory = 'Sell Category',
  SellerHub = 'Seller Hub',
  SellerHubBoostListings = 'Seller Hub - Boost listings',
  SellerHubDrafts = 'Seller Hub - Drafts',
  SellerHubOffers = 'Seller Hub - Offers',
  SellerHubSelling = 'Seller Hub - Selling',
  SellerHubSold = 'Seller Hub - Sold',
  SellerHubStats = 'Seller Hub - Stats',
  SellerHubVacation = 'Seller Hub - Vacation',
  SellerHubShipping = 'Seller Hub - Shipping',
  SellerOnboarding = 'Seller Onboarding',
  SellerStats = 'Seller Stats',
  Selling = 'Selling',
  Settings = 'Settings',
  Shop = 'Shop',
  Signup = 'Signup',
  Sitemap = 'Sitemap',
  Sold = 'Sold',
  Spaces = 'Spaces',
  Theme = 'Theme',
}

export enum RouteNames {
  ABOUT_PAGE = 'about.view',
  BAG_PAGE = 'bag.view',
  BLOG_PAGE = 'blog.view',
  BLOG_ARTICLE_PAGE = 'blogArticle.view',
  BRAND_CATEGORY_RESULTS_PAGE = 'brandCategoryResults.view',
  BRAND_RESULTS_PAGE = 'brandResults.view',
  BULK_LISTING_PAGE = 'bulkListing.view',
  CATEGORY_RESULTS_PAGE = 'categoryResults.view',
  CATEGORY_SELL_PAGE = 'categorySell.view',
  CHAT_VIEW = 'chat.view',
  CHECKOUT_ADDRESS_SELECTION = 'address.selection.view',
  CHECKOUT_PAGE = 'checkout.view',
  CHECKOUT_START_PAYMENT_PAGE = 'checkout.view.startPayment',
  CHECKOUT_SUCCESS_PAGE = 'purchaseConfirmation.view',
  DISPUTES_DASHBOARD_PAGE = 'disputes.dashboard.view',
  DISPUTES_FLOW_PAGE = 'disputes.flow.view',
  DISPUTES_INITIATE_PAGE = 'disputes.initiate.view',
  DISPUTES_PAGE = 'disputes.view',
  DRAFTS_COMPLETE_PAGE = 'listing.drafts.complete.view',
  DRAFTS_INCOMPLETE_PAGE = 'listing.drafts.incomplete.view',
  DRAFTS_BULK_LISTING_PAGE = 'listing.drafts.bulkListing.view',
  EXPLORE_PAGE = 'explore.view',
  EXPLORE_RESULTS_PAGE = 'explore.results.view',
  HOME_PAGE = 'homepage.view',
  LEGACY_SELLER_ONBOARDING_BILLING_PAGE = 'sellerOnboarding.Billing.view',
  LEGACY_SELLER_ONBOARDING_PAYPAL_PAGE = 'sellerOnboarding.PayPalStart.view',
  LEGACY_SELLER_ONBOARDING_SHOPBASICS_PAGE = 'sellerOnboarding.ShopBasics.view',
  LIKES_PAGE = 'userProfile.likes.view',
  LISTING_PAGE = 'listing.view',
  LOGIN_PAGE = 'login.view',
  MAGIC_LINK_INVALID_PAGE = 'magiclink.invalid.view',
  MAGIC_LINK_CHECK_INBOX_PAGE = 'magiclink.checkInbox.view',
  MAGIC_LINK_MISSING_LINK_PAGE = 'magiclink.missingLink.view',
  MAGIC_LINK_VERIFY_PAGE = 'magiclink.verify.view',
  MAGIC_LINK_REQUEST_PAGE = 'magiclink.request.view',
  MARKETING_PAGE = 'marketing.view',
  MARKETING_SUBSCRIBE_PAGE = 'marketing.subscribe.view',
  MARKETING_UNSUBSCRIBE_PAGE = 'marketing.unsubscribe.view',
  MFA_HELP_PAGE = 'mfaLogin.Fallback.view',
  MFA_LOGIN_PAGE = 'mfaLogin.OTPInput.view',
  NO_MATCH = '',
  OFFERS_PAGE = 'offers.view',
  PASSWORD_RESET_PAGE = 'passwordReset.view',
  PRODUCT_PAGE = 'product.view',
  PURCHASES_PAGE = 'purchases.view',
  RECEIPTS_BUYER_PAGE = 'receipts.buyer.view',
  RECEIPT_BUYER_PAGE = 'receipt.buyer.view',
  RECEIPT_DETAILS_PAGE = 'receipts.details.view',
  RECEIPT_LIST_PAGE = 'receipts.list.view',
  SAVE_PAGE = 'userProfile.saved.view',
  SEARCH_PAGE = 'shopnow.view',
  SEARCH_RESULTS_PAGE = 'searchResults.view',
  SELLER_HUB_BOOST_LISTINGS_BOOSTED_PAGE = 'sellerHub.boostListings.boostedTab.view',
  SELLER_HUB_BOOST_LISTINGS_PAGE = 'sellerHub.boostListings.view',
  SELLER_HUB_DRAFTS_COMPLETE_PAGE = 'sellerHub.drafts.complete.view',
  SELLER_HUB_DRAFTS_INCOMPLETE_PAGE = 'sellerHub.drafts.incomplete.view',
  SELLER_HUB_DRAFTS_PAGE = 'sellerHub.drafts.view',
  SELLER_HUB_OFFERS_PAGE = 'sellerHub.offers.view',
  SELLER_HUB_PAGE = 'sellerHub.view',
  SELLER_HUB_SELLING_PAGE = 'sellerHub.selling.view',
  SELLER_HUB_SOLD_PAGE = 'sellerHub.sold.view',
  SELLER_HUB_STATS_PAGE = 'sellerHub.stats.view',
  SELLER_HUB_VACATION_PAGE = 'sellerHub.vacation.view',
  SELLER_HUB_SHIPPING_PAGE = 'sellerHub.shipping.view',
  SELLER_ONBOARDING_BILLING_ADDRESS_PAGE = 'setup.shop.billing.address.v2.view',
  SELLER_ONBOARDING_COMPANY_PAGE = 'setup.shop.business.onboarding.v2.view',
  SELLER_ONBOARDING_COMPANY_VERIFY_FAILURE_PAGE = 'setup.shop.business.onboarding.verify.failure.v2.view',
  SELLER_ONBOARDING_COMPANY_VERIFY_SUCCESS_PAGE = 'setup.shop.business.onboarding.verify.success.v2.view',
  SELLER_ONBOARDING_DEPOP_BALANCE_PAGE = 'setup.shop.depop.payments.v2.view',
  SELLER_ONBOARDING_DOB_PAGE = 'setup.shop.dateofbirth.v2.view',
  SELLER_ONBOARDING_INTRO_PAGE = 'setup.shop.intro.v2.view',
  SELLER_ONBOARDING_PAGE = 'sellerOnboarding.view',
  SELLER_ONBOARDING_PAYPAL_PAGE = 'setup.shop.connect.paypal.v2.view',
  SELLING_PAGE = 'userProfile.selling.view',
  SELL_CATEGORY_PAGE = 'sellcategory.view',
  SELL_ON_DEPOP_PAGE = 'sellondepop.view',
  SELL_PAGE = 'sell.view',
  SETTINGS_PAGE = 'settings.view',
  SHOP_PAGE = 'shop.view',
  SIGNUP_PAGE = 'signup.view',
  SIGNUP_LOGIN_MAGIC_LINK = 'signupLogin.magiclink.view',
  SIGNUP_LOGIN_MAGIC_LINK_SENT = 'signupLogin.magiclink.sent.view',
  SIGNUP_LOGIN_MAGIC_LINK_HELP = 'signupLogin.magiclink.help.view',
  SIGNUP_LOGIN_MAGIC_LINK_ERROR = 'signupLogin.magicLink.error.view',
  SIGNUP_MAGIC_LINK_VERIFY = 'signup.magiclink.verify',
  SITEMAP_PAGE = 'sitemap.view',
  SOLD_PAGE = 'userProfile.sold.view',
  SPACES_PAGE = 'spaces.view',
  STATS_PAGE = 'sellerStats.view',
  THEME_PAGE = 'theme.view',
  USER_SHOP_PAGE = 'userProfile.shop.view',
}

type Route = {
  route: RouteNames;
  groupName: GroupNames;
};

/*
  This helper allows us to get autosuggestions when referencing the
  routePaths dictionary.
  Suggested here: https://stackoverflow.com/a/52157355/18506083
*/
function asStrings<T extends { [key: string]: Route }>(arg: T): T {
  return arg;
}

export const routePaths = asStrings({
  HOME_PAGE: { route: RouteNames.HOME_PAGE, groupName: GroupNames.Homepage },
  EXPLORE_PAGE: {
    route: RouteNames.EXPLORE_PAGE,
    groupName: GroupNames.Explore,
  },
  EXPLORE_RESULTS_PAGE: {
    route: RouteNames.EXPLORE_RESULTS_PAGE,
    groupName: GroupNames.ExploreResults,
  },
  SEARCH_PAGE: { route: RouteNames.SEARCH_PAGE, groupName: GroupNames.Search },
  SEARCH_RESULTS_PAGE: {
    route: RouteNames.SEARCH_RESULTS_PAGE,
    groupName: GroupNames.SearchResults,
  },
  BRAND_RESULTS_PAGE: {
    route: RouteNames.BRAND_RESULTS_PAGE,
    groupName: GroupNames.BrandResults,
  },
  CATEGORY_RESULTS_PAGE: {
    route: RouteNames.CATEGORY_RESULTS_PAGE,
    groupName: GroupNames.CategoryResults,
  },
  BRAND_CATEGORY_RESULTS_PAGE: {
    route: RouteNames.BRAND_CATEGORY_RESULTS_PAGE,
    groupName: GroupNames.BrandCategoryResults,
  },
  LOGIN_PAGE: { route: RouteNames.LOGIN_PAGE, groupName: GroupNames.Login },
  THEME_PAGE: { route: RouteNames.THEME_PAGE, groupName: GroupNames.Theme },
  MESSAGES_PAGE: { route: RouteNames.CHAT_VIEW, groupName: GroupNames.Chat },
  OFFERS_PAGE: { route: RouteNames.OFFERS_PAGE, groupName: GroupNames.Offers },
  LISTING_PAGE: {
    route: RouteNames.LISTING_PAGE,
    groupName: GroupNames.Listing,
  },
  PRODUCT_PAGE: {
    route: RouteNames.PRODUCT_PAGE,
    groupName: GroupNames.Product,
  },
  ABOUT_PAGE: { route: RouteNames.ABOUT_PAGE, groupName: GroupNames.About },
  SPACES_PAGE: { route: RouteNames.SPACES_PAGE, groupName: GroupNames.Spaces },
  STATS_PAGE: {
    route: RouteNames.STATS_PAGE,
    groupName: GroupNames.SellerStats,
  },
  DRAFTS_INCOMPLETE_PAGE: {
    route: RouteNames.DRAFTS_INCOMPLETE_PAGE,
    groupName: GroupNames.Drafts,
  },
  DRAFTS_COMPLETE_PAGE: {
    route: RouteNames.DRAFTS_COMPLETE_PAGE,
    groupName: GroupNames.Drafts,
  },
  DRAFTS_BULK_LISTING_PAGE: {
    route: RouteNames.DRAFTS_BULK_LISTING_PAGE,
    groupName: GroupNames.Drafts,
  },
  LIKES_PAGE: { route: RouteNames.LIKES_PAGE, groupName: GroupNames.Likes },
  SAVE_PAGE: { route: RouteNames.SAVE_PAGE, groupName: GroupNames.Saves },
  SOLD_PAGE: { route: RouteNames.SOLD_PAGE, groupName: GroupNames.Sold },
  SELLING_PAGE: {
    route: RouteNames.SELLING_PAGE,
    groupName: GroupNames.Selling,
  },
  RECEIPT_DETAILS_PAGE: {
    route: RouteNames.RECEIPT_DETAILS_PAGE,
    groupName: GroupNames.Receipts,
  },
  RECEIPT_LIST_PAGE: {
    route: RouteNames.RECEIPT_LIST_PAGE,
    groupName: GroupNames.Receipts,
  },
  SHOP_PAGE: { route: RouteNames.USER_SHOP_PAGE, groupName: GroupNames.Shop },
  SELL_ON_DEPOP_PAGE: {
    route: RouteNames.SELL_ON_DEPOP_PAGE,
    groupName: GroupNames.Sell,
  },
  SELL_CATEGORY_PAGE: {
    route: RouteNames.SELL_CATEGORY_PAGE,
    groupName: GroupNames.CategorySell,
  },
  MFA_LOGIN_PAGE: {
    route: RouteNames.MFA_LOGIN_PAGE,
    groupName: GroupNames.Login,
  },
  MFA_HELP_PAGE: {
    route: RouteNames.MFA_HELP_PAGE,
    groupName: GroupNames.Login,
  },
  LEGACY_SELLER_ONBOARDING_SHOPBASICS_PAGE: {
    route: RouteNames.LEGACY_SELLER_ONBOARDING_SHOPBASICS_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  LEGACY_SELLER_ONBOARDING_PAYPAL_PAGE: {
    route: RouteNames.LEGACY_SELLER_ONBOARDING_PAYPAL_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  LEGACY_SELLER_ONBOARDING_BILLING_PAGE: {
    route: RouteNames.LEGACY_SELLER_ONBOARDING_BILLING_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_INTRO_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_INTRO_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_COMPANY_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_COMPANY_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_COMPANY_VERIFY_SUCCESS_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_COMPANY_VERIFY_SUCCESS_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_COMPANY_VERIFY_FAILURE_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_COMPANY_VERIFY_FAILURE_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_DOB_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_DOB_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_BILLING_ADDRESS_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_BILLING_ADDRESS_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_PAYPAL_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_PAYPAL_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_ONBOARDING_DEPOP_BALANCE_PAGE: {
    route: RouteNames.SELLER_ONBOARDING_DEPOP_BALANCE_PAGE,
    groupName: GroupNames.SellerOnboarding,
  },
  SELLER_HUB_PAGE: {
    route: RouteNames.SELLER_HUB_PAGE,
    groupName: GroupNames.SellerHub,
  },
  SELLER_HUB_BOOST_LISTINGS_ALL_PAGE: {
    route: RouteNames.SELLER_HUB_BOOST_LISTINGS_PAGE,
    groupName: GroupNames.SellerHubBoostListings,
  },
  SELLER_HUB_BOOST_LISTINGS_BOOSTED_PAGE: {
    route: RouteNames.SELLER_HUB_BOOST_LISTINGS_BOOSTED_PAGE,
    groupName: GroupNames.SellerHubBoostListings,
  },
  SELLER_HUB_SELLING_PAGE: {
    route: RouteNames.SELLER_HUB_SELLING_PAGE,
    groupName: GroupNames.SellerHubSelling,
  },
  SELLER_HUB_DRAFTS_INCOMPLETE_PAGE: {
    route: RouteNames.SELLER_HUB_DRAFTS_INCOMPLETE_PAGE,
    groupName: GroupNames.SellerHubDrafts,
  },
  SELLER_HUB_DRAFTS_COMPLETE_PAGE: {
    route: RouteNames.SELLER_HUB_DRAFTS_COMPLETE_PAGE,
    groupName: GroupNames.SellerHubDrafts,
  },
  SELLER_HUB_STATS_PAGE: {
    route: RouteNames.SELLER_HUB_STATS_PAGE,
    groupName: GroupNames.SellerHubStats,
  },
  SELLER_HUB_SOLD_PAGE: {
    route: RouteNames.SELLER_HUB_SOLD_PAGE,
    groupName: GroupNames.SellerHubSold,
  },
  SELLER_HUB_OFFERS_PAGE: {
    route: RouteNames.SELLER_HUB_OFFERS_PAGE,
    groupName: GroupNames.SellerHubOffers,
  },
  SELLER_HUB_VACATION_PAGE: {
    route: RouteNames.SELLER_HUB_VACATION_PAGE,
    groupName: GroupNames.SellerHubVacation,
  },
  SELLER_HUB_SHIPPING_PAGE: {
    route: RouteNames.SELLER_HUB_SHIPPING_PAGE,
    groupName: GroupNames.SellerHubShipping,
  },
  SITEMAP_PAGE: {
    route: RouteNames.SITEMAP_PAGE,
    groupName: GroupNames.Sitemap,
  },
  CHECKOUT_LANDING_PAGE: {
    route: RouteNames.CHECKOUT_PAGE,
    groupName: GroupNames.Checkout,
  },
  CHECKOUT_ADDRESS_SELECTION: {
    route: RouteNames.CHECKOUT_ADDRESS_SELECTION,
    groupName: GroupNames.Checkout,
  },
  CHECKOUT_START_PAYMENT_PAGE: {
    route: RouteNames.CHECKOUT_START_PAYMENT_PAGE,
    groupName: GroupNames.Checkout,
  },
  CHECKOUT_SUCCESS_PAGE: {
    route: RouteNames.CHECKOUT_SUCCESS_PAGE,
    groupName: GroupNames.Checkout,
  },
  DISPUTES_DASHBOARD_PAGE: {
    route: RouteNames.DISPUTES_DASHBOARD_PAGE,
    groupName: GroupNames.Disputes,
  },
  DISPUTES_INITIATE_PAGE: {
    route: RouteNames.DISPUTES_INITIATE_PAGE,
    groupName: GroupNames.Disputes,
  },
  DISPUTES_FLOW_PAGE: {
    route: RouteNames.DISPUTES_FLOW_PAGE,
    groupName: GroupNames.Disputes,
  },
  SIGNUP: {
    route: RouteNames.SIGNUP_PAGE,
    groupName: GroupNames.Signup,
  },
  SELL_CATEGORY: {
    route: RouteNames.SELL_CATEGORY_PAGE,
    groupName: GroupNames.SellCategory,
  },
  BULK_LISTING: {
    route: RouteNames.BULK_LISTING_PAGE,
    groupName: GroupNames.BulkListing,
  },
  PURCHASES_LIST_PAGE: {
    route: RouteNames.RECEIPTS_BUYER_PAGE,
    groupName: GroupNames.Purchases,
  },
  PURCHASE_DETAILS_PAGE: {
    route: RouteNames.RECEIPT_BUYER_PAGE,
    groupName: GroupNames.Purchases,
  },
  BAG_PAGE: {
    route: RouteNames.BAG_PAGE,
    groupName: GroupNames.Bag,
  },
  SETTINGS_PAGE: {
    route: RouteNames.SETTINGS_PAGE,
    groupName: GroupNames.Settings,
  },
  PASSWORD_RESET_PAGE: {
    route: RouteNames.PASSWORD_RESET_PAGE,
    groupName: GroupNames.PasswordReset,
  },
  MARKETING_SUBSCRIBE_PAGE: {
    route: RouteNames.MARKETING_SUBSCRIBE_PAGE,
    groupName: GroupNames.Marketing,
  },
  MARKETING_UNSUBSCRIBE_PAGE: {
    route: RouteNames.MARKETING_UNSUBSCRIBE_PAGE,
    groupName: GroupNames.Marketing,
  },
  MAGIC_LINK_INVALID_PAGE: {
    route: RouteNames.MAGIC_LINK_INVALID_PAGE,
    groupName: GroupNames.MagicLink,
  },
  MAGIC_LINK_CHECK_INBOX_PAGE: {
    route: RouteNames.MAGIC_LINK_CHECK_INBOX_PAGE,
    groupName: GroupNames.MagicLink,
  },
  MAGIC_LINK_MISSING_LINK_PAGE: {
    route: RouteNames.MAGIC_LINK_MISSING_LINK_PAGE,
    groupName: GroupNames.MagicLink,
  },
  MAGIC_LINK_VERIFY_PAGE: {
    route: RouteNames.MAGIC_LINK_VERIFY_PAGE,
    groupName: GroupNames.MagicLink,
  },
  MAGIC_LINK_REQUEST_PAGE: {
    route: RouteNames.MAGIC_LINK_REQUEST_PAGE,
    groupName: GroupNames.MagicLink,
  },
  BLOG_PAGE: {
    route: RouteNames.BLOG_PAGE,
    groupName: GroupNames.Blog,
  },
  BLOG_ARTICLE_PAGE: {
    route: RouteNames.BLOG_ARTICLE_PAGE,
    groupName: GroupNames.Blog,
  },
  SIGNUP_LOGIN_MAGIC_LINK: {
    route: RouteNames.SIGNUP_LOGIN_MAGIC_LINK,
    groupName: GroupNames.MagicLink,
  },
  SIGNUP_LOGIN_MAGIC_LINK_SENT: {
    route: RouteNames.SIGNUP_LOGIN_MAGIC_LINK_SENT,
    groupName: GroupNames.MagicLink,
  },
  SIGNUP_LOGIN_MAGIC_LINK_HELP: {
    route: RouteNames.SIGNUP_LOGIN_MAGIC_LINK_HELP,
    groupName: GroupNames.MagicLink,
  },
  SIGNUP_LOGIN_MAGIC_LINK_ERROR: {
    route: RouteNames.SIGNUP_LOGIN_MAGIC_LINK_ERROR,
    groupName: GroupNames.MagicLink,
  },
  SIGNUP_MAGIC_LINK_VERIFY: {
    route: RouteNames.SIGNUP_MAGIC_LINK_VERIFY,
    groupName: GroupNames.MagicLink,
  },
  NO_MATCH: {
    route: RouteNames.NO_MATCH,
    groupName: GroupNames.NoMatch,
  },
});

export const SEARCH_RESULT_BOOSTED_VIEW_ROUTE = 'searchResults.boosted.view';

export enum Platform {
  Web = 'web',
  iOS = 'iOS',
  Android = 'Android',
}

export enum NavCtaActionTypes {
  NAV_LOGIN_CTA = 'nav_login_cta',
  NAV_SIGNUP_CTA = 'nav_signup_cta',
  DEPOP_LOGO_CTA = 'depop_logo_cta',
}
