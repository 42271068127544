import { getSupportedLocations } from '../location/helpers';

import {
  routePaths,
  INTERNAL_REFERER,
  TrackingReferers,
  refererKeys,
  refererMatches,
} from './constants';

type RouteGroupType = (typeof routePaths)[keyof typeof routePaths];

type RefererType =
  | TrackingReferers
  | RouteGroupType['route']
  | typeof INTERNAL_REFERER;

function referredFromInternal(host: string): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  const currentLocation = new URL(window.location.href);
  return host === currentLocation.host;
}

const supportedLocations = getSupportedLocations(
  process.env.NEXT_PUBLIC_SUPPORTED_LOCATIONS || ''
);
const homepageGetPathRegex = new RegExp(
  `^(?:\\/(${supportedLocations.join('|')}))?\\/(?:\\?.*)?$`
);
/**
 * @TECHDEBT PB 10/1/24
 * This function is called an awful lot. It takes ~0.05ms to run
 * so is not an issue in and of itself, but it could be a hint at
 * suboptimal rerendering patterns stemming from activity tracking.
 */
// TODO: Ensure these are valid NextJS routes
function getPath(path: string): RouteGroupType {
  if (path.match(homepageGetPathRegex)) {
    return routePaths.HOME_PAGE;
  }
  if (path.includes('/disputes/')) {
    if (path.includes('/initiate')) {
      return routePaths.DISPUTES_INITIATE_PAGE;
    }
    if (path.match(/\/disputes\/.+\//)) {
      return routePaths.DISPUTES_FLOW_PAGE;
    }
    return routePaths.DISPUTES_DASHBOARD_PAGE;
  }
  if (path.includes('/search/')) {
    if (path.match(/\/?q=/)) {
      return routePaths.SEARCH_RESULTS_PAGE;
    }
    return routePaths.SEARCH_PAGE;
  }
  if (path.match(/^\/magic-link(\/.*)?$/)) {
    if (path.includes('/check-inbox/')) {
      return routePaths.SIGNUP_LOGIN_MAGIC_LINK_SENT;
    }
    if (path.includes('/help/')) {
      return routePaths.SIGNUP_LOGIN_MAGIC_LINK_HELP;
    }
    if (path.includes('/error/')) {
      return routePaths.SIGNUP_LOGIN_MAGIC_LINK_ERROR;
    }
    return routePaths.SIGNUP_LOGIN_MAGIC_LINK;
  }
  if (path.includes('/login')) {
    if (path.includes('/magic-link/invalid-link')) {
      return routePaths.MAGIC_LINK_INVALID_PAGE;
    }
    if (path.includes('/magic-link/check-inbox')) {
      return routePaths.MAGIC_LINK_CHECK_INBOX_PAGE;
    }
    if (path.includes('/magic-link/missing-link')) {
      return routePaths.MAGIC_LINK_MISSING_LINK_PAGE;
    }
    if (path.includes('/magic-link/verify/')) {
      return routePaths.MAGIC_LINK_VERIFY_PAGE;
    }
    if (path.includes('/magic-link')) {
      return routePaths.MAGIC_LINK_REQUEST_PAGE;
    }
    return routePaths.LOGIN_PAGE;
  }
  if (path.includes('/theme/')) {
    return routePaths.THEME_PAGE;
  }
  if (path.includes('/sitemap')) {
    return routePaths.SITEMAP_PAGE;
  }
  if (path.includes('/brands/')) {
    if (path.includes('/c/')) {
      return routePaths.BRAND_CATEGORY_RESULTS_PAGE;
    }
    return routePaths.BRAND_RESULTS_PAGE;
  }
  if (path.includes('/category/')) {
    return routePaths.CATEGORY_RESULTS_PAGE;
  }
  if (path.includes('/messages')) {
    if (path.includes('/messages/offers')) {
      return routePaths.OFFERS_PAGE;
    }
    return routePaths.MESSAGES_PAGE;
  }
  if (path.includes('/products/')) {
    if (path.includes('/get-started/shop-basics')) {
      return routePaths.LEGACY_SELLER_ONBOARDING_SHOPBASICS_PAGE;
    }
    if (path.includes('/get-started/get-paid')) {
      return routePaths.LEGACY_SELLER_ONBOARDING_PAYPAL_PAGE;
    }
    if (path.includes('/get-started/billing')) {
      return routePaths.LEGACY_SELLER_ONBOARDING_BILLING_PAGE;
    }
    if (path.match(/\/create\/$/) || path.match(/\/edit\/.*/)) {
      return routePaths.LISTING_PAGE;
    }
    return routePaths.PRODUCT_PAGE;
  }
  if (path.includes('/about')) {
    if (path.includes('spaces')) {
      return routePaths.SPACES_PAGE;
    }
    return routePaths.ABOUT_PAGE;
  }
  if (path.match(/\/[_a-z0-9]+\/drafts/)) {
    if (path.match(/\/edit\/.*/)) {
      return routePaths.LISTING_PAGE;
    }
    if (path.includes('/sellinghub/')) {
      if (path.includes('incomplete')) {
        return routePaths.SELLER_HUB_DRAFTS_INCOMPLETE_PAGE;
      }
      if (path.includes('readyToPost')) {
        return routePaths.SELLER_HUB_DRAFTS_COMPLETE_PAGE;
      }
    }
    if (path.match(/^\/sellinghub\/drafts\/$/)) {
      return routePaths.DRAFTS_BULK_LISTING_PAGE;
    }
    if (path.includes('incomplete')) {
      return routePaths.DRAFTS_INCOMPLETE_PAGE;
    }
    if (path.includes('readyToPost')) {
      return routePaths.DRAFTS_COMPLETE_PAGE;
    }
  }
  if (path.match(/\/[_a-z0-9]+\/likes/)) {
    return routePaths.LIKES_PAGE;
  }
  if (path.match(/\/[_a-z0-9]+\/saved/)) {
    return routePaths.SAVE_PAGE;
  }
  if (path.includes('/sellinghub/selling')) {
    return routePaths.SELLER_HUB_SELLING_PAGE;
  }
  if (path.includes('/sellinghub/stats')) {
    return routePaths.SELLER_HUB_STATS_PAGE;
  }
  if (path.includes('/sellinghub/boost/all')) {
    return routePaths.SELLER_HUB_BOOST_LISTINGS_ALL_PAGE;
  }
  if (path.includes('/sellinghub/boost/boosted')) {
    return routePaths.SELLER_HUB_BOOST_LISTINGS_BOOSTED_PAGE;
  }
  if (path.includes('/sellinghub/sold-items')) {
    /*
          @TODO: We need clarity on what the correct transitionFrom
          values should be here as we have a mix of receipts and
          seller hub namespaces
        */
    if (path.match(/\/sellinghub\/sold-items\/[0-9]+/)) {
      return routePaths.RECEIPT_DETAILS_PAGE;
    }
    return routePaths.SELLER_HUB_SOLD_PAGE;
  }
  if (path.includes('/sellinghub/bulklisting')) {
    return routePaths.BULK_LISTING;
  }
  if (path.includes('/sellinghub/offers')) {
    return routePaths.SELLER_HUB_OFFERS_PAGE;
  }
  if (path.includes('/sellinghub/vacation')) {
    return routePaths.SELLER_HUB_VACATION_PAGE;
  }
  if (path.includes('/sellinghub/shipping')) {
    return routePaths.SELLER_HUB_SHIPPING_PAGE;
  }
  if (path.includes('/sellinghub/')) {
    return routePaths.SELLER_HUB_PAGE;
  }
  if (path.match(/\/[_a-z0-9]+\/selling/)) {
    return routePaths.SELLING_PAGE;
  }
  if (path.match(/\/[_a-z0-9]+\/sold/)) {
    return routePaths.SOLD_PAGE;
  }
  if (path.includes('/stats')) {
    return routePaths.STATS_PAGE;
  }
  if (path.includes('/signup')) {
    if (path.includes('/magic-link/verify/')) {
      return routePaths.SIGNUP_MAGIC_LINK_VERIFY;
    }
    return routePaths.SIGNUP;
  }
  if (path.includes('/bag')) {
    return routePaths.BAG_PAGE;
  }
  if (path.includes('/blog')) {
    if (path.match(/\/blog\/[a-zA-Z0-9]+/)) {
      return routePaths.BLOG_ARTICLE_PAGE;
    }
    return routePaths.BLOG_PAGE;
  }
  if (path.includes('/start-selling')) {
    if (path.includes('company')) {
      return routePaths.SELLER_ONBOARDING_COMPANY_PAGE;
    }
    if (path.includes('dob')) {
      return routePaths.SELLER_ONBOARDING_DOB_PAGE;
    }
    if (path.includes('billing-address')) {
      return routePaths.SELLER_ONBOARDING_BILLING_ADDRESS_PAGE;
    }
    if (path.includes('paypal')) {
      return routePaths.SELLER_ONBOARDING_PAYPAL_PAGE;
    }
    if (path.includes('depop-balance')) {
      return routePaths.SELLER_ONBOARDING_DEPOP_BALANCE_PAGE;
    }
    if (path.includes('stripe/account-verification')) {
      if (path.includes('success')) {
        return routePaths.SELLER_ONBOARDING_COMPANY_VERIFY_SUCCESS_PAGE;
      }
      return routePaths.SELLER_ONBOARDING_COMPANY_VERIFY_FAILURE_PAGE;
    }
  }
  if (path.includes('/pay')) {
    if (path.includes('/pay/success')) {
      return routePaths.CHECKOUT_SUCCESS_PAGE;
    }

    return routePaths.CHECKOUT_LANDING_PAGE;
  }
  if (path.includes('/sell/')) {
    if (path.match(/\/sell\/[a-zA-Z0-9]+/)) {
      return routePaths.SELL_CATEGORY;
    }
    return routePaths.SELL_ON_DEPOP_PAGE;
  }
  if (path.includes('/explore')) {
    if (path.match(/\/explore\/[a-zA-Z0-9]+/)) {
      return routePaths.EXPLORE_RESULTS_PAGE;
    }
    return routePaths.EXPLORE_PAGE;
  }
  if (path.includes('/purchases')) {
    if (path.match(/\/purchases\/[0-9]+/)) {
      return routePaths.PURCHASE_DETAILS_PAGE;
    }
    return routePaths.PURCHASES_LIST_PAGE;
  }
  if (path.includes('/settings')) {
    return routePaths.SETTINGS_PAGE;
  }
  if (path.includes('/password-reset')) {
    return routePaths.PASSWORD_RESET_PAGE;
  }
  if (path.includes('/marketing/unsubscribe')) {
    return routePaths.MARKETING_UNSUBSCRIBE_PAGE;
  }
  if (path.includes('/marketing/subscribe')) {
    return routePaths.MARKETING_SUBSCRIBE_PAGE;
  }
  if (path.match(/\/[_a-zA-Z0-9]+/)) {
    return routePaths.SHOP_PAGE;
  }
  return routePaths.NO_MATCH;
}

export function getRouteView(path: string): RouteGroupType['route'] {
  return getPath(path).route;
}

export function getRouteName(path: string): RouteGroupType['groupName'] {
  return getPath(path).groupName;
}

export function getReferer(refererUrl?: string | null): RefererType {
  if (!refererUrl) {
    return INTERNAL_REFERER;
  }
  const { pathname, host } = new URL(refererUrl);

  if (host === null || pathname === null) {
    return INTERNAL_REFERER;
  }

  if (referredFromInternal(host)) {
    return getPath(pathname).route;
  }

  for (const refererMatch in refererMatches) {
    const { hostMatch, referer } = refererMatches[refererMatch as refererKeys];

    if (host.includes(hostMatch)) {
      if (refererMatch === 'instagram') {
        if (pathname.includes('stories')) {
          return TrackingReferers.INSTAGRAM_STORIES;
        }
      }

      return referer;
    }
  }

  return TrackingReferers.OTHER;
}

export function isExternalReferer(referer?: string): boolean {
  return referer ? !referer.includes('depop.com') : false;
}
