import { SupportedLocation } from './constants';

export function getValidLocation(
  location: string,
  validLocations: string[],
  defaultLocation: string
) {
  if (validLocations.includes(location.toLowerCase())) {
    return location.toLowerCase();
  }
  return defaultLocation;
}

export function getSupportedLocations(locations: string) {
  return locations.split(' ') || [];
}

export function isSupportedLocation(
  location: string
): location is SupportedLocation {
  return Object.values(SupportedLocation).includes(
    location as SupportedLocation
  );
}
