export const LOCAL_STORAGE_LOCATION_PREF = 'DEPOP_LISTING_LOCATION_PREFERENCES';
export const LOCAL_STORAGE_SHIPPING_PREF = 'LOCAL_STORAGE_SHIPPING_PREF';
export const LOCAL_STORAGE_NATIONAL_SHIPPING_COST =
  'LOCAL_STORAGE_NATIONAL_SHIPPING_COST';
export const LOCAL_STORAGE_INTERNATIONAL_SHIPPING_COST =
  'LOCAL_STORAGE_INTERNATIONAL_SHIPPING_COST';
export const LOCAL_STORAGE_DEPOP_SHIPPING_COST =
  'LOCAL_STORAGE_DEPOP_SHIPPING_COST';
export const LOCAL_STORAGE_SHIPPING_DETAILS = 'LOCAL_STORAGE_SHIPPING_DETAILS';

export enum SupportedLocation {
  AU = 'au',
  DE = 'de',
  FR = 'fr',
  GB = 'gb',
  IT = 'it',
  US = 'us',
  IE = 'ie',
  CA = 'ca',
  NZ = 'nz',
}

export type LocationsEnabled = {
  [SupportedLocation.AU]?: boolean;
  [SupportedLocation.DE]?: boolean;
  [SupportedLocation.FR]?: boolean;
  [SupportedLocation.GB]?: boolean;
  [SupportedLocation.IT]?: boolean;
  [SupportedLocation.US]?: boolean;
  [SupportedLocation.IE]?: boolean;
  [SupportedLocation.CA]?: boolean;
  [SupportedLocation.NZ]?: boolean;
};

export enum CurrencyCode {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  AUD = 'AUD',
  NZD = 'NZD',
  CAD = 'CAD',
}

export type SupportedLocations = keyof LocationsEnabled;

export type Location = {
  key: string;
  value: SupportedLocation;
  name: string;
  defaultCurrency: CurrencyCode;
};

export const locations: Location[] = [
  {
    key: 'CountryName.Australia',
    value: SupportedLocation.AU,
    name: 'Australia',
    defaultCurrency: CurrencyCode.AUD,
  },
  {
    key: 'CountryName.Germany',
    value: SupportedLocation.DE,
    name: 'Germany',
    defaultCurrency: CurrencyCode.EUR,
  },
  {
    key: 'CountryName.France',
    value: SupportedLocation.FR,
    name: 'France',
    defaultCurrency: CurrencyCode.EUR,
  },
  {
    key: 'CountryName.UnitedKingdom',
    value: SupportedLocation.GB,
    name: 'United Kingdom',
    defaultCurrency: CurrencyCode.GBP,
  },
  {
    key: 'CountryName.Italy',
    value: SupportedLocation.IT,
    name: 'Italy',
    defaultCurrency: CurrencyCode.EUR,
  },
  {
    key: 'CountryName.UnitedStates',
    value: SupportedLocation.US,
    name: 'United States',
    defaultCurrency: CurrencyCode.USD,
  },
  {
    key: 'CountryName.Ireland',
    value: SupportedLocation.IE,
    name: 'Ireland',
    defaultCurrency: CurrencyCode.EUR,
  },
  {
    key: 'CountryName.Canada',
    value: SupportedLocation.CA,
    name: 'Canada',
    defaultCurrency: CurrencyCode.CAD,
  },
  {
    key: 'CountryName.NewZealand',
    value: SupportedLocation.NZ,
    name: 'New Zealand',
    defaultCurrency: CurrencyCode.NZD,
  },
];

export type EN_LANG_LOCALE =
  | SupportedLocation.AU
  | SupportedLocation.GB
  | SupportedLocation.US
  | SupportedLocation.IE
  | SupportedLocation.CA
  | SupportedLocation.NZ;

export const EN_LANG_LOCALES = [
  SupportedLocation.AU,
  SupportedLocation.GB,
  SupportedLocation.US,
  SupportedLocation.IE,
  SupportedLocation.CA,
  SupportedLocation.NZ,
];

export const EU_LOCALES = [
  SupportedLocation.DE,
  SupportedLocation.FR,
  SupportedLocation.IE,
  SupportedLocation.IT,
];

export const FREE_FEE_LOCALES = [SupportedLocation.GB, SupportedLocation.US];
