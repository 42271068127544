export function buildEndpoint(path: string) {
  return `${process.env.NEXT_PUBLIC_API_HOST}${path}`;
}

export function buildStaticUrl(path: string) {
  return process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST + path;
}

export function removeHostFromEndpoint(path: string) {
  return path.replace(process.env.NEXT_PUBLIC_API_HOST, '');
}

export function buildPhotoroomEndpoint(path: string) {
  return `${process.env.NEXT_PUBLIC_PHOTOROOM_URL}${path}`;
}

export const AUTH_IDENTIFY_ENDPOINT = buildEndpoint('/api/v1/auth/identify/');

export const REVOKE_TOKEN_ENDPOINT = buildEndpoint('/oauth2/token/revoke/');

export const REFERRAL_EVENT_ENDPOINT = buildEndpoint('/api/v1/referral-event/');
export const ACTIVITY_TRACKER_EVENT_ENDPOINT =
  buildEndpoint('/api/v2/at/event/');
export const ACTIVITY_TRACKER_SESSION_ENDPOINT = buildEndpoint(
  '/api/v2/at/session/'
);
export const APPLE_LOGIN_ENDPOINT = buildEndpoint('/api/v1/auth/loginApple/');
export const APPLE_CREDENTIALS_ENDPOINT = buildEndpoint(
  '/api/v1/auth/appleCredentials/'
);
export const BAG_BASIC_ENDPOINT = buildEndpoint(
  '/presentation/api/v1/bags/me/basic/'
);
export const BAG_DETAILED_ENDPOINT = buildEndpoint('/api/v3/bag/detailed/');
export const UPDATE_BAG_ENDPOINT = buildEndpoint(
  '/presentation/api/v1/bags/me/'
);
export const BUCKET_EXPERIMENTS_ENDPOINT = buildEndpoint(
  `/api/v1/features/experiments/`
);
export const PREVIEW_EXPERIMENTS_ENDPOINT = buildEndpoint(
  `/api/v1/features/experiments/preview/`
);
export const WEB_SEGMENT_CONSENT_ENDPOINT = buildEndpoint(
  `/internal/v1/consent/web/:uuid/`
);
export const USER_COUNTERS_ENDPOINT = buildEndpoint(
  `/api/v1/users/:userId/counters/`
);
export const BRAND_CATEGORY_PAGE_METADATA_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/brand/:brandSlug/category/:categoryId/'
);
export const CATEGORY_PAGE_METADATA_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/category/:categoryId/'
);
export const CATEGORY_SEARCH_SUGGESTIONS_ENDPOINT = buildEndpoint(
  `/api/v2/search/categorySearchSuggestions/`
);
export const CATEGORIES_BY_PATH_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/categories/byPath/'
);
export const CATEGORIES_BY_ID_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/categories/byId/'
);
export const CONTENTFUL_ENDPOINT = buildEndpoint('/presentation/api/v1/cms/');

export const DISCOUNT_ENDPOINT = buildEndpoint('/api/v2/discounts/');

export const DISCOUNT_SHOP_WIDE_ENDPOINT = buildEndpoint(
  '/api/v2/discounts/shop/'
);

export const DISCOUNT_JOB_STATUS_ENDPOINT = buildEndpoint(
  '/api/v2/discounts/shop/:jobId/'
);

export const NUDGES_ENDPOINT = buildEndpoint(
  '/api/v1/product/nudges/products/:productIds/'
);

export const PRODUCT_SEARCH_ENDPOINT = buildEndpoint(
  '/api/v3/search/products/'
);

export const EXPLORE_ENDPOINT = buildEndpoint('/api/v2/search/explore/');

export const EXPLORE_STYLE_EDIT_ENDPOINT = buildEndpoint(
  '/api/v3/search/explore/styleEdit/'
);

export const FILTER_AGGREGATES = buildEndpoint(
  '/api/v3/search/filters/aggregates/'
);

export const SIZE_FILTER_TREE = buildEndpoint('/api/v2/search/sizeFilters/');

export const LEGACY_CATEGORY_FILTERS = buildEndpoint(
  '/api/v2/search/categoryFilters/'
);

export const CATEGORY_FILTERS = buildEndpoint(
  '/api/v3/search/categoryFilters/'
);

export const BILLING_AGREEMENT_TOKEN_ENDPOINT = buildEndpoint(
  '/api/v1/sellerOnboarding/payPal/billingAgreement/'
);

export const BILLING_AGREEMENT_SUBMISSION_ENDPOINT = buildEndpoint(
  '/api/v1/sellerOnboarding/payPal/billingAgreement/'
);

export const CREATE_STRIPE_USER_ENDPOINT = buildEndpoint(
  '/api/v1/sellerOnboarding/stripe/users/'
);

export const SELLER_STATUS_ENDPOINT = buildEndpoint(
  '/api/v1/sellerOnboarding/sellerStatus/'
);

export const DEPOP_PAYMENTS_ELIGIBILITY_ENDPOINT = buildEndpoint(
  '/api/v1/sellerOnboarding/stripe/eligibility/'
);

export const SELLER_VACATION_ENDPOINT = buildEndpoint('/api/v1/vacation/me/');

export const RECEIPT_BY_ID_ENDPOINT = buildEndpoint(
  '/api/v1/receipts/:purchaseId/'
);

export const RECEIPTS_ENDPOINT = buildEndpoint('/api/v1/receipts/');

export const SALES_REPORT_ENDPOINT = buildEndpoint(
  '/api/v1/receipts/sales-report/'
);

export const SELLER_HISTORICAL_DATA_ENDPOINT = buildEndpoint(
  '/api/v1/stats/sellers/historical/:metric/'
);

export const MFA_CREATE_CHALLENGE_ENDPOINT = buildEndpoint(
  '/api/v1/auth/mfa/devices/:deviceId/challenge/'
);

export const MFA_DEVICES_ENDPOINT = buildEndpoint('/api/v1/auth/mfa/devices/');

export const PASSWORD_RESET_ENDPOINT = buildEndpoint(
  '/api/v1/auth/password/reset/'
);

export const PASSWORD_RESET_CONFIRM_ENDPOINT = buildEndpoint(
  '/api/v1/auth/password/reset/confirm/'
);

export const PASSWORD_RESET_TOKEN_ENDPOINT = buildEndpoint(
  '/api/v1/auth/password/reset/:token/'
);

export const PRODUCT_ATTRIBUTES_ENDPOINT = buildEndpoint(
  '/api/v2/search/filters/productAttributes/'
);

export const FILTER_PRODUCT_ATTRIBUTES_ENDPOINT = buildEndpoint(
  '/api/v2/search/filters/filterProductAttributes/'
);

export const BULK_LISTING_ENABLED_ENDPOINT = buildEndpoint(
  '/api/v1/bulkactions/listing/enabled/'
);

export const SHOP_BY_USERNAME_ENDPOINT = buildEndpoint(
  '/api/v1/shop/:username/'
);

export const BATCH_SHOP_BY_USERNAME_ENDPOINT = buildEndpoint(
  '/api/v1/shop/batch/:usernames/'
);

export const SHOP_BY_ID_ENDPOINT = buildEndpoint(
  '/api/v2/shop/findByUserId/:userId/'
);

export const SHOP_PRODUCTS_BY_USER_ID_ENDPOINT = buildEndpoint(
  '/api/v1/shop/:userId/products/'
);

export const SHOP_PRODUCTS_BY_USER_ID_V2_ENDPOINT = buildEndpoint(
  '/api/v2/shop/:userId/products/'
);

export const SHOP_PRODUCTS_BY_USER_ID_V3_ENDPOINT = buildEndpoint(
  '/api/v3/shop/:userId/products/'
);

export const SEARCH_SHOP_PRODUCTS_BY_USER_ID_ENDPOINT = buildEndpoint(
  '/api/v1/shop/:userId/products/search/'
);

export const SHOP_FILTER_AGGREGATES = buildEndpoint(
  '/api/v1/shop/:sellerId/filters/aggregates/'
);

export const SHOP_REARRANGE_PRODUCTS = buildEndpoint(
  '/api/v1/shop/:userId/rearrange/'
);

export const SHOP_BULK_SOLD_PRODUCTS_ENDPOINT = buildEndpoint(
  '/api/v1/sold-products/'
);

export const FOLLOWING_RELATIONSHIP_ENDPOINT = buildEndpoint(
  '/api/v1/users/:userId/viewer-meta/'
);

export const DELETE_FOLLOWING_ENDPOINT = buildEndpoint(
  '/api/v1/users/:userId/following/:sellerId/'
);

export const FOLLOWERS_ENDPOINT = buildEndpoint(
  '/api/v1/users/:sellerId/followers/'
);

export const FOLLOWING_ENDPOINT = buildEndpoint(
  '/api/v1/users/:sellerId/following/'
);

export const USER_EDUCATION_VIEW_COUNTS_ENDPOINT = buildEndpoint(
  `/api/v1/user/education/viewCounts/`
);

export const SIGNUP_TOKEN_VALIDATION_ENDPOINT = buildEndpoint(
  '/api/v1/users/verification/email/'
);

export const BOOSTED_IMPRESSIONS_ATTRIBUTION_ENDPOINT = buildEndpoint(
  '/api/v1/boost/attributions/batch/'
);

export const SHIPPING_PREFERENCES_ENDPOINT = buildEndpoint(
  `/api/v1/shipping-preferences/`
);

export const SHIPPING_PROVIDERS_ENDPOINT = buildEndpoint(
  `/api/v1/shipping-providers/`
);

export const MARK_AS_SHIPPED_ENDPOINT = buildEndpoint(
  `/api/v1/shipping/mark-as-shipped/:parcelId/`
);

export const MANUAL_SHIPPING_PROVIDERS_ENDPOINT = buildEndpoint(
  `/api/v1/shipping/manualShippingProviders/`
);

export const SHIPPING_LABEL_DOWNLOAD_ENDPOINT = buildEndpoint(
  `/api/v1/shipping/label/:id/`
);

export const USER_SETTINGS = buildEndpoint('/api/v1/user/settings/');

export const UPDATE_PRODUCT_LIKE_ENDPOINT = buildEndpoint(
  `/api/v1/product/:productId/likes/`
);

export const BOOSTED_CLICK_ATTR_ENDPOINT =
  buildEndpoint(`/api/v1/boost/click/`);

export const BOOSTED_LISTINGS_ENDPOINT = buildEndpoint('/api/v1/boost/');

export const BOOSTED_LISTINGS_BOOSTED_ENDPOINT = buildEndpoint(
  '/api/v1/boost/boosted/'
);

export const BOOSTING_STATS_ENDPOINT = buildEndpoint('/api/v1/boost/stats/');

export const USER_BALANCE = buildEndpoint('/api/v1/stripe/balance/');

export const SAVED_PRODUCTS_ENDPOINT = buildEndpoint('/api/v1/user/saves/');

export const SAVED_PRODUCTS_V2_ENDPOINT = buildEndpoint('/api/v2/user/saves/');

export const LIKED_PRODUCTS_ENDPOINT = buildEndpoint(
  '/api/v1/shop/:userId/likes/'
);

export const LIKED_PRODUCTS_V2_ENDPOINT = buildEndpoint(
  '/api/v2/shop/:userId/likes/'
);

export const ORDERED_BRANDS_ENDPOINT = buildEndpoint(
  `/api/v2/search/attributes/brands/ordered/`
);

export const BRANDS_BY_ID_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/brands/byId/'
);
export const BRANDS_BATCH_BY_ID_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/brands/batchById/:brandIds/'
);

export const BRANDS_BATCH_BY_SLUG_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/brands/batchBySlug/:brandSlugs/'
);

export const USER_FEEDBACK_ENDPOINT = buildEndpoint(
  '/api/v1/:sellerId/feedback/'
);

export const FEEDBACK_ENDPOINT = buildEndpoint('/api/v1/feedback/');

export const CREATE_PRODUCT_ENDPOINT = buildEndpoint(`/api/v2/products/`);

export const BULK_POST_DRAFTS_ASYNC_ENDPOINT = buildEndpoint(
  `/api/v3/drafts/products/`
);

export const BULK_POST_DRAFTS_ASYNC_STATUS_ENDPOINT = buildEndpoint(
  `/api/v3/drafts/products/:id/status/`
);
export const UPDATE_PRODUCT_BY_SLUG = buildEndpoint('/api/v2/products/:slug/`');

export const POST_DRAFTS_ENDPOINT = buildEndpoint(`/api/v2/drafts/products/`);

export const DRAFTS_BATCH_ENDPOINT = buildEndpoint(`/api/v1/drafts/batch/`);

export const DELETE_DRAFTS_ENDPOINT = buildEndpoint(
  `/api/v1/drafts/batch/:draftIds/`
);

export const DRAFTS_ENDPOINT = buildEndpoint(`/api/v2/drafts/`);

export const DRAFTS_BY_ID_V1 = buildEndpoint(`/api/v1/drafts/:draftId/`);

export const DRAFTS_BY_ID_V2 = buildEndpoint(`/api/v2/drafts/:draftId/`);

export const ADDRESS_SEARCH_TEXT = buildEndpoint(
  `/presentation/api/v1/addresses/search/text/`
);

export const ADDRESS_SEARCH_REFINED_RESULTS = buildEndpoint(
  `/presentation/api/v1/addresses/search/:id/results/`
);

export const ADDRESS_SEARCH_DETAIL = buildEndpoint(
  `/presentation/api/v1/addresses/search/:id/detail/`
);

export const USER_ADDRESSES = buildEndpoint(`/presentation/api/v1/addresses/`);

// proxy endpoint for address PATCH - no trailing backslash
export const USER_UPDATE_ADDRESS = buildEndpoint(`/v1/addresses/:addressId`);

export const ACTIVE_SHOP_PRODUCTS_ENDPOINT = buildEndpoint(
  `/api/v1/shop/:userId/filteredProducts/selling/`
);

export const ACTIVE_SHOP_PRODUCTS_V2_ENDPOINT = buildEndpoint(
  `/api/v2/shop/:userId/filteredProducts/selling/`
);

export const SOLD_SHOP_PRODUCTS_ENDPOINT = buildEndpoint(
  `/api/v1/shop/:userId/filteredProducts/sold/`
);

export const SOLD_SHOP_PRODUCTS_V2_ENDPOINT = buildEndpoint(
  `/api/v2/shop/:userId/filteredProducts/sold/`
);

export const LEGACY_PRODUCT_BY_ID = buildEndpoint(
  `/api/v2/product/by-id/:productId/`
);

export const PRODUCT_BY_ID_ENDPOINT = buildEndpoint(
  `/api/v3/product/:productId/`
);

export const PRODUCT_BY_SLUG = buildEndpoint(
  `/api/v1/product/by-slug/:slug/basic/`
);

export const PRODUCT_BY_SLUG_EXTENDED = buildEndpoint(
  `/api/v1/product/by-slug/:slug/extended/`
);

export const PRODUCT_BY_SLUG_LISTING = buildEndpoint(`/api/v2/products/:slug/`);

export const PRODUCT_MORE_ITEMS = buildEndpoint(
  '/api/v2/product/:productId/moreItems/'
);

export const PERFORMANCE_METRICS = buildEndpoint(
  `/api/v1/performance/collect/`
);

export const SINGLE_OUTFIT_ENDPOINT = buildEndpoint(
  '/api/v1/outfits/:outfitId/'
);

export const PAGINATED_OUTFITS_ENDPOINT = buildEndpoint('/api/v1/outfits/');

export const PAGINATED_IMPORTED_OUTFITS_ENDPOINT = buildEndpoint(
  '/api/v1/outfits/from-listings/'
);

export const CREATE_PICTURE_V3 = buildEndpoint('/api/v4/pictures/');

export const CREATE_OUTFIT_ENDPOINT = buildEndpoint('/api/v1/outfits/');

export const OUTFITS_CONFIG_ENDPOINT = buildEndpoint('/api/v1/outfits/config/');

export const DELETE_BATCH_OUTFITS_ENDPOINT = buildEndpoint(
  '/api/v1/outfits/delete/'
);

export const LIKE_OUTFIT_ENDPOINT = buildEndpoint(
  '/api/v1/outfits/:outfitId/like/'
);

export const COLLAGE_RECOMMENDED_ITEMS = buildEndpoint(
  '/api/v1/outfits/collage-items/recs/'
);

export const COLLAGE_PRODUCTS_CATEGORIES = buildEndpoint(
  '/api/v1/outfits/collage-items/meta/tabs/'
);

export const STORIES_CONFIG_ENDPOINT = buildEndpoint('/api/v1/stories/config/');

export const STORIES_ENDPOINT = buildEndpoint('/api/v1/stories/');

export const STORIES_BY_USERNAME_ENDPOINT = buildEndpoint(
  '/api/v1/stories/feed/by-username/:username'
);

export const DELETE_STORY_ENDPOINT = buildEndpoint('/api/v1/stories/:storyId');

export const PHOTOROOM_IMAGE_EDIT_ENDPOINT =
  buildPhotoroomEndpoint('/v1/segment');

export const SELLER_PRODUCTS_LISTED = buildEndpoint(
  '/api/v2/product/counters/listed/'
);

export const SELLER_PRODUCTS = buildEndpoint('/api/v1/shop/products/');

export const MAGIC_LINK_REQUEST_ENDPOINT = buildEndpoint(
  '/api/v1/auth/login/magic-link/'
);

export const MAGIC_LINK_REQUEST_SYNC_ENDPOINT = buildEndpoint(
  '/api/v1/auth/login/magic-link/sync/'
);

export const MAGIC_LINK_REGISTER_ENDPOINT = buildEndpoint(
  '/api/v1/auth/signup/magic-link/'
);

export const LOGIN = buildEndpoint('/api/v1/auth/login/');

export const BRAND_PAGE_BY_SLUG_ENDPOINT = buildEndpoint(
  '/api/v2/search/attributes/brand/:slug/'
);

export const CATEGORIES_ENDPOINT = buildEndpoint('/api/v1/categories/');

export const CATEGORY_SIZE_MAP_ENDPOINT = buildEndpoint(
  '/api/v2/search/category/sizes/'
);

export const CHECKOUT_SUMMARY_ENDPOINT = buildEndpoint(
  '/api/v2/checkout/checkoutSummary/'
);
export const START_PURCHASE_ENDPOINT = buildEndpoint(
  '/api/v1/checkout/purchase/'
);
export const CANCEL_PURCHASE_ENDPOINT = buildEndpoint(
  '/api/v1/checkout/purchase/:purchaseId/'
);
export const COMPLETE_PURCHASE_ENDPOINT = buildEndpoint(
  '/api/v1/checkout/purchase/:purchaseId/complete/'
);
export const PURCHASE_COUNTS_ENDPOINT = buildEndpoint(
  '/api/v1/checkout/purchaseCounts/withUser/:userId/'
);

export const SIMILAR_ITEMS_ENDPOINT = buildEndpoint(
  '/api/v3/product/:productId/similar/'
);

export const UPDATE_SAVED_PRODUCT_ENDPOINT = buildEndpoint(
  '/api/v1/product/:productId/save/'
);

export const DELETE_PRODUCTS_ENDPOINT = buildEndpoint('/api/v1/products/');

export const FETCH_RECENTLY_VIEWED_ENDPOINT = buildEndpoint(
  '/api/v2/user/recentlyviewed/'
);

export const UPDATE_RECENTLY_VIEWED_ENDPOINT = buildEndpoint(
  '/api/v1/recentlyviewed/:userId/'
);

export const DELETE_RECENTLY_VIEWED_ENDPOINT = buildEndpoint(
  '/api/v1/recentlyviewed/:userId/'
);

export const EMAIL_AVAILABILITY_ENDPOINT = buildEndpoint(
  '/api/v1/auth/signup/emailAvailability/'
);

export const USERNAME_AVAILABILITY_ENDPOINT = buildEndpoint(
  '/api/v1/auth/checkusername/:username/'
);

export const PASSWORD_VALIDATION_ENDPOINT = buildEndpoint(
  '/api/v1/auth/signup/validatePassword/'
);

export const OFFERS_ENDPOINT = buildEndpoint('/api/v2/offers/');

export const COUNTER_OFFER_ENDPOINT = buildEndpoint(
  '/api/v2/offers/:offer_id/'
);

export const OFFERS_PRICE_QUOTE_ENDPOINT = buildEndpoint(
  '/api/v1/offers/price-quote/'
);

export const COUNTER_OFFERS_PRICE_QUOTE_ENDPOINT = buildEndpoint(
  '/api/v1/offers/:offerId/price-quote/'
);

export const BUYER_ACTIVE_OFFERS_ENDPOINT = buildEndpoint(
  '/api/v3/offers/active/'
);

export const BUYER_INACTIVE_OFFERS_ENDPOINT = buildEndpoint(
  '/api/v4/offers/inactive/'
);

export const DISMISS_OFFER_ENDPOINT = buildEndpoint(
  '/api/v1/offers/:offerId/dismiss/'
);

export const VERIFY_PHONE_NUMBER_ENDPOINT = buildEndpoint(
  '/api/v1/auth/verify/phone/'
);

export const VERIFY_PHONE_NUMBER_CONFIRM_ENDPOINT = buildEndpoint(
  '/api/v1/auth/verify/confirm/'
);

export const SIGNUP_ENDPOINT = buildEndpoint('/api/v1/auth/signup/');

export const MARKETING_CONSENT_PREFERENCES_ENDPOINT = buildEndpoint(
  '/api/v1/consent/preferences/'
);

export const MARKETING_CONSENT_ENDPOINT = buildEndpoint('/api/v1/consent/');

export const FETCH_PAYMENT_PROVIDERS_ENDPOINT = buildEndpoint(
  '/api/v1/checkout/users/:sellerId/paymentProviders/'
);

export const CONVERSATIONS_LIST = buildEndpoint('/api/v1/chat/conversations/');

export const FETCH_CONVERSATION_MESSAGES = buildEndpoint(
  '/api/v1/chat/messages/:conversationId/'
);

export const FETCH_CONVERSATION_BY_ID = buildEndpoint(
  '/api/v1/chat/conversations/:conversationId/'
);

export const FETCH_CONVERSATION_BY_USER_ID = buildEndpoint(
  '/api/v1/chat/conversations/findByUserId/:userId/'
);

export const HIDE_CONVERSATION_BY_ID = buildEndpoint(
  '/api/v1/chat/conversations/:conversationId/hide/'
);

export const MARK_CONVERSATION_AS_READ = buildEndpoint(
  '/api/v1/chat/conversations/:conversationId/markAsRead/'
);

export const MARK_CONVERSATION_AS_UNREAD = buildEndpoint(
  '/api/v1/chat/conversations/:conversationId/markAsUnread/'
);

export const SEND_MESSAGE = buildEndpoint('/api/v1/chat/messages/');

export const LISTING_COUNTRIES = buildStaticUrl(
  '/web/assets/listing/location/countries.json'
);

export const FETCH_TEMPLATES = buildEndpoint('/api/v1/templates/');
